import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StudentPage.css';
import { jwtDecode } from 'jwt-decode';
import { useQuestions } from './useQuestions';
import { QuestionButton } from './QuestionButton';
import { QuestionForm } from './QuestionForm';
import { AskQuestions } from './AskQuestions';

function StudentPage({ fileName, userId }) {
  const [lines, setLines] = useState([]);
  const [lineAudios, setLineAudios] = useState({});
  const [studentAudios, setStudentAudios] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [savedWords, setSavedWords] = useState({}); 
  const [isDisable, setIsDisable] = useState({});
  const [assignedTeacherId, setAssignedTeacherId] = useState(null);
  const [pendingThisAudio, setPendingThisAudio] = useState(null);
  const needTeacher = true;
  const {
    questions,
    isAskingQuestion,
    isQuestionExpanded,
    questionText,
    setQuestionText,
    questionAudio,
    setQuestionAudio,
    handleQuestionToggle,
    handleSubmitQuestion,
    fetchQuestions,
    startQuestionRecording,
    stopQuestionRecording,
    renderAudio,
    remainingPoints,
    estimatedPoints,
    handleTextChange,
    pendingAudio,
    followupText,
    handleSubmitFollowup,
    setFollowupText,
    setAssignTeacher,
    assignTeacher,
  } = useQuestions(fileName, userId, needTeacher);
  let mediaRecorder = null;
  let audioChunks = [];
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const AUDIO_URL = BACKEND_URL.split("/api")[0];

  useEffect(() => {
    const token = localStorage.getItem('token');
  
  
    // 定义异步函数
    const fetchAssignedTeacherId = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/students/me`, {
          headers: {
            'Authorization': `Bearer ${token}`  // 在请求头中添加 token
          }
        });
  
        // 成功获取返回的数据
        setAssignedTeacherId(response.data.assigned_teacher_id);
      } catch (error) {
        console.error('Error fetching assigned teacher ID:', error);
      }
    };
  
    // 调用异步函数
    fetchAssignedTeacherId();
    fetchQuestions();
  }, []);  // [] 表示只在组件挂载时执行
  

  // 获取问题数据
  useEffect(() => {
    const fetchLinesAndQuestions = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('token:', token);
        // 获取页面行数据
        const linesResponse = await axios.get(`${BACKEND_URL}/detailed/${fileName}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLines(linesResponse.data.lines);
        setLineAudios(linesResponse.data.line_audios || {});
        // 获取学生的录音数据
        const studentAudioResponse = await axios.get(`${BACKEND_URL}/student_audios/${fileName}/${userId}`);
        setStudentAudios(Array.isArray(studentAudioResponse.data) ? studentAudioResponse.data : []);
        // 获取所有单词的音标、解释和音频信息
        const wordsResponse = await axios.get(`${BACKEND_URL}/detailed_get_words/${fileName}`);
        console.log('wordsResponse:', wordsResponse);
        const wordsData = wordsResponse.data.reduce((acc, wordData) => {
          acc[wordData.word] = wordData;
          return acc;
        }, {});
        setSavedWords(wordsData);  // 保存单词信息
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    if (userId) {
      console.log('userId00:', userId);
      fetchLinesAndQuestions();
    }
  }, [fileName, userId]);
  
  const getLineClass = (label) => {
    switch (label) {
      case "美音":
        return "line-american";
      case "发音技巧":
        return "line-pronunciation";
      case "音节分解":
        return "line-syllable";
      default:
        return "line-none";
    }
  };
  
  const splitLineIntoWords = (lineContent) => {
    const isChinese = (char) => char >= "\u4e00" && char <= "\u9fff";
  
    const words = lineContent.split(/(\s+)/);
  
    return words.map((word, index) => {
      const wordClean = word.trim().toLowerCase().replace(/^[,.!?'"()]+|[,.!?'"()]+$/g, "");
  
      if (Array.from(wordClean).some(isChinese)) {
        return <span key={index}>{word}</span>;
      }
  
      return (
        <span
          key={index}
          onClick={() => handleWordClick(wordClean)}
          style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
        >
          {word}
        </span>
      );
    });
  };
  
  const handleWordClick = (word) => {
    const cleanWord = removePunctuation(word);
    if (savedWords[cleanWord]) {
      const wordData = savedWords[cleanWord];
      console.log('wordData:', wordData);
  
      // 将音频对象转化为数组形式 [{ filename, label }]
      const audioFiles = Object.entries(wordData.audio || {}).map(([filename, label]) => ({
        filename,
        label,
      }));
  
      setModalContent({
        word: cleanWord,
        pronunciation: wordData.pronunciation,
        meaning: wordData.meanings,
        audioFiles,
      });
    } else {
      setModalContent({
        word: cleanWord,
        pronunciation: '无音标',
        meaning: '没有该单词的信息',
        audioFiles: [],
      });
    }
    setModalVisible(true);
  };
  

  const removePunctuation = (word) => word.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').trim();

  let isRecording = false;

  const startRecording = async (timestampId) => {
    if (isRecording) return;
    isRecording = true;
  
    console.log("尝试开始录音");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : MediaRecorder.isTypeSupported("audio/webm")
        ? "audio/webm"
        : "audio/aac";
  
      mediaRecorder = new MediaRecorder(stream, { mimeType });
      audioChunks = [];
  
      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };
  
      mediaRecorder.start();
      console.log("录音已启动，格式:", mimeType);
    } catch (error) {
      console.error("无法启动录音:", error);
      alert("无法访问麦克风，请检查权限设置或切换浏览器再试。");
    }
  };
  
  const stopRecording = (timestampId) => {
    if (!isRecording) return;
    isRecording = false;
  
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        const mimeType = mediaRecorder.mimeType;
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        const audioURL = URL.createObjectURL(audioBlob);
        setPendingThisAudio({ timestampId, audioBlob, audioURL });
        console.log("录音完成，格式:", mimeType);
      };
    }
  };
  

// 上传录音
const handleUploadAudio = async () => {
  const { timestampId, audioBlob } = pendingThisAudio;
  if (!audioBlob) {
    alert("没有录音可上传");
    return;
  }

  // 动态确定文件扩展名
  const extension = audioBlob.type.includes("mp4")
    ? "mp4"
    : audioBlob.type.includes("aac")
    ? "aac"
    : "webm";
  const formData = new FormData();
  const audioFilename = `${timestampId}_${userId}.${extension}`; // 动态设置扩展名

  formData.append("audio", audioBlob, audioFilename);

  try {
    const response = await axios.post(
      `${BACKEND_URL}/upload_student_audio`,
      formData,
      {
        params: { filename: fileName, timestampId, userId },
      }
    );

    setStudentAudios((prevAudios) => [
      ...prevAudios,
      { audio_file: response.data.filename, timestamp_id: timestampId },
    ]);
    setPendingThisAudio(null); // 上传成功后清除 pendingThisAudio
    console.log("录音上传成功:", response.data.filename);
  } catch (error) {
    console.error("Error uploading audio:", error);
    alert("上传失败");
  }
};


  // 删除录音
  const handleDeleteAudio = async (timestampId, audioFile) => {
    if (window.confirm("确定要删除该录音吗？")) {
      try {
        await axios.delete(`${BACKEND_URL}/delete_student_audio/${fileName}/${timestampId}/${userId}`);
        setStudentAudios((prevAudios) => prevAudios.filter(audio => audio.timestamp_id !== timestampId));
      } catch (error) {
        console.error('Error deleting audio:', error);
        alert('删除失败');
      }
    }
  };

  // 检查是否有学生录音，返回音频播放控件
  const renderStudentAudio = (timestampId) => {
    const studentAudio = studentAudios.find(audio => audio.timestamp_id === timestampId);
    if (studentAudio) {
      return (
        <div>
          <audio controls src={`${AUDIO_URL}/audio/${studentAudio.audio_file}?t=${new Date().getTime()}`} />
          <button onClick={() => handleDeleteAudio(timestampId, studentAudio.audio_file)}>删除录音</button>
          <span>已上传</span>
        </div>
      );
    } else if (pendingThisAudio && pendingThisAudio.timestampId === timestampId) {
      return (
        <div>
          <audio controls src={pendingThisAudio.audioURL} />
          <button onClick={handleUploadAudio}>上传</button>
          <button onClick={() => setPendingThisAudio(null)}>删除</button>
        </div>
      );
    }
    return null;  // 如果没有学生录音，不显示音频控件
  };

  return (
    <div>
      <h1>详解</h1>
      <div>
        {lines.map((line, index) => {
          const [contentText, currentLabel] = Object.entries(line.content)[0];
          return (
            <div key={index} className={getLineClass(currentLabel)}>
              <p style={{ whiteSpace: "pre-wrap" }}>{splitLineIntoWords(contentText)}</p>

              {/* 显示录音 */}
              {lineAudios[line.timestamp_id] && (
                <div>
                  {Object.entries(lineAudios[line.timestamp_id]).map(([audio, label]) => (
                    <div key={audio} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                      <span style={{ marginRight: "10px", fontWeight: "bold" }}>{label || "None"}</span>
                      <audio
                        controls
                        src={`${AUDIO_URL}/audio/${audio}?t=${new Date().getTime()}`}
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  ))}
                </div>
              )}


              {contentText.trim() === "" && (
                <>
                  <button
                    id={line.timestamp_id}
                    className="no-select"
                    onPointerDown={(e) => {
                      e.preventDefault(); // 确保触发链正确传递
                      document.getElementById(line.timestamp_id).innerText = "录音中...";
                      startRecording(line.timestamp_id);
                    }}
                    onPointerUp={(e) => {
                      e.preventDefault();
                      document.getElementById(line.timestamp_id).innerText = "重新录音";
                      stopRecording(line.timestamp_id);
                    }}
                    disabled={isDisable.timestampId}
                  >
                    {pendingThisAudio && pendingThisAudio.timestampId === line.timestamp_id
                      ? "重新录音"
                      : "用户录音"}
                  </button>

                  {renderStudentAudio(line.timestamp_id)}
                  <QuestionButton
                      timestampId={line.timestamp_id}
                      questions={questions}
                      isQuestionExpanded={isQuestionExpanded}
                      isAskingQuestion={isAskingQuestion}
                      handleQuestionToggle={handleQuestionToggle}
                  />
                  {isAskingQuestion[line.timestamp_id] && (
                  <QuestionForm
                      questionText={questionText}
                      setQuestionText={setQuestionText}
                      questionAudio={questionAudio}
                      setQuestionAudio={setQuestionAudio}
                      handleSubmitQuestion={handleSubmitQuestion}
                      timestampId={line.timestamp_id}
                      startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
                      stopQuestionRecording={stopQuestionRecording}
                      remainingPoints={remainingPoints}
                      estimatedPoints = {estimatedPoints}
                      handleTextChange = {handleTextChange}
                      renderAudio={renderAudio}
                      pendingAudio={pendingAudio}
                      setAssignTeacher={setAssignTeacher}
                      assignTeacher={assignTeacher}
                      needTeacher={needTeacher}
                  />
                  )}
                  {isQuestionExpanded[line.timestamp_id] && (
                  <AskQuestions
                      questionText={questionText}
                      setQuestionText={setQuestionText}
                      questionAudio={questionAudio}
                      setQuestionAudio={setQuestionAudio}
                      handleSubmitQuestion={handleSubmitQuestion}
                      timestampId={line.timestamp_id}
                      startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
                      stopQuestionRecording={stopQuestionRecording}
                      remainingPoints={remainingPoints}
                      estimatedPoints = {estimatedPoints}
                      handleTextChange = {handleTextChange}
                      renderAudio={renderAudio}
                      pendingAudio={pendingAudio}
                      qTimestampId={'qtimestamp_id'}
                      followupText={followupText}
                      handleSubmitFollowup={handleSubmitFollowup}
                      setFollowupText={setFollowupText}
                      questions={questions}
                      needTeacher={needTeacher}
                  />
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>


      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h2>{modalContent.word}</h2>
            <p>音标: {modalContent.pronunciation}</p>
            <p>解释: {modalContent.meaning}</p>
            {modalContent.audioFiles.length > 0 ? (
              modalContent.audioFiles.map(({ filename, label }, i) => (
                <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <span style={{ marginRight: '10px', fontWeight: 'bold' }}>{label || 'None'}:</span>
                  <audio controls src={`${AUDIO_URL}/audio/${filename}?t=${new Date().getTime()}`} />
                </div>
              ))
            ) : (
              <p>无音频文件</p>
            )}
            <button onClick={() => setModalVisible(false)}>关闭</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentPage;
