import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './DetailedPage.css';

function DetailedPage({ filename }) {
  const [lines, setLines] = useState([]);
  const [lineAudios, setLineAudios] = useState({});
  const [isLineRecording, setIsLineRecording] = useState(null);  // 行录音
  const [modalVisible, setModalVisible] = useState(false);  // 控制模态框的显示
  const [modalContent, setModalContent] = useState({});  // 存储查询到的单词详情
  const [selectedMeanings, setSelectedMeanings] = useState([]);  // 存储已选择的解释
  const [selectedAudios, setSelectedAudios] = useState([]);  // 存储已选择的音频
  const [isWordRecording, setIsWordRecording] = useState(false);  // 单词录音
  const [pronunciation, setPronunciation] = useState(''); // 音标状态
  const [savedSelections, setSavedSelections] = useState({}); // 保存的选择状态
  const [editingLine, setEditingLine] = useState(null);  // 当前编辑的行号
  const [newLineContent, setNewLineContent] = useState('');  // 新行的内容
  const [maxLineNumber, setMaxLineNumber] = useState(0);  // 最大行号
  const [maxTimestampId, setMaxTimestampId] = useState(0);  // 最大timestamp_id
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState('0:00');
  const audioRef = useRef(null);
  let mediaRecorder = null;
  let audioChunks = [];
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const SHORT_URL = BACKEND_URL.split("/api")[0];
  // 页面加载时获取 lines 和 line_audios 以及 detailed_saved_selections
  useEffect(() => {
    const fetchLines = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BACKEND_URL}/detailed/${filename}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const parsedLines = response.data.lines;
        setLines(response.data.lines);
        // 获取最大line_number和timestamp_id
        const maxLineNumber = Math.max(...parsedLines.map(line => line.line_number));
        const maxTimestampId = Math.max(...parsedLines.map(line => parseInt(line.timestamp_id.split('-')[1])));
        setMaxLineNumber(maxLineNumber);
        console.log('maxLineNumber:', maxLineNumber);
        setMaxTimestampId(maxTimestampId);
        setLineAudios(response.data.line_audios);
        setSavedSelections(response.data.detailed_saved_selections || {}); // 加载保存的选择
      } catch (error) {
        console.error('Error fetching detailed page:', error);
      }
    };

    fetchLines();
  }, [filename]);

  // 去除标点符号用于查询
  const removePunctuation = (word) => {
    return word.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').trim();
  };

  // 处理单词点击，打开模态框并查询单词详情
  const handleWordClick = async (word) => {
    const cleanWord = removePunctuation(word).toLowerCase();
    console.log('cleanWord:', cleanWord);
  
    if (!cleanWord) return;
  
    try {
      const response = await axios.get(`${BACKEND_URL}/word_details/${cleanWord}`);
      const wordData = response.data;
      console.log('wordData:', wordData);
  
      const pronunciation = wordData.pronunciation || '';
      const meaning = wordData.meaning ? wordData.meaning.split(';') : []; // 分割解释
  
      // 将音频文件解析为数组形式，同时包含标签
      const audioFiles = wordData.audio_files
        ? Object.entries(wordData.audio_files).map(([filename, label]) => ({
            filename,
            label,
          }))
        : [];
  
      // 确保 savedSelections 中的值为字符串或适配所需格式
      const savedMeaning = savedSelections[cleanWord]?.selected_meanings || '';
      const savedAudios = savedSelections[cleanWord]?.selected_audios || {};
  
      setModalContent({
        word: cleanWord,
        pronunciation,
        meaning,
        audioFiles,
      });
  
      // 确保类型一致性
      setSelectedMeanings(typeof savedMeaning === 'string' ? savedMeaning.split(';') : []);
      setSelectedAudios(
        typeof savedAudios === 'object'
          ? Object.keys(savedAudios) // 提取音频文件名
          : []
      );
      setPronunciation(savedSelections[cleanWord]?.pronunciation || pronunciation);
  
      setModalVisible(true); // 显示模态框
    } catch (error) {
      console.error('Error fetching word details:', error);
      setModalContent({
        word: cleanWord,
        pronunciation: '',
        meaning: [],
        audioFiles: [],
      });
      setModalVisible(true); // 即使失败也显示模态框
    }
  };
  
  // 处理解释的选择变更
  const handleMeaningChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(option => option.value);
    setSelectedMeanings(selectedOptions);
  };

  // 处理音频的选择变更
  const handleAudioChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(option => option.value);
    setSelectedAudios(selectedOptions);
  };

  // 保存当前单词的选择（解释、音频、音标）
  const handleSave = async () => {
    console.log('selectedAudios:', selectedAudios);
    console.log('modalContent.audioFiles:', modalContent.audioFiles);
  
    // 构建新的 selected_audios 格式
    const audioWithLabels = selectedAudios.reduce((acc, audio) => {
      const label = modalContent.audioFiles.find(({ filename }) => filename === audio)?.label || "None";
      acc[audio] = label;
      return acc;
    }, {});
  
    const updatedSelections = {
      pronunciation,
      selected_meanings: selectedMeanings.join(';'),
      selected_audios: audioWithLabels, // 改为字典格式
    };
  
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${BACKEND_URL}/detailed_saved_selections/${filename}`, {
        word: modalContent.word,
        selections: updatedSelections,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // 更新本地保存状态
      setSavedSelections(prevSelections => ({
        ...prevSelections,
        [modalContent.word]: updatedSelections,
      }));
  
      alert('保存成功');
    } catch (error) {
      console.error('Error saving selections:', error);
      alert('保存失败');
    }
  };

  // 添加修改解释的函数
  const handleModifyMeaning = async () => {
    const currentMeaning = modalContent.meaning.join(';');
    const newMeaning = window.prompt("修改解释", currentMeaning);
    if (newMeaning !== null && newMeaning !== currentMeaning) {
      // 调用后端接口更新解释
      try {
        const token = localStorage.getItem('token');
        await axios.post(`${BACKEND_URL}/update_meaning/${modalContent.word}`, {
          new_meaning: newMeaning,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // 更新modalContent.meaning和selectedMeanings
        const updatedMeanings = newMeaning.split(';');
        setModalContent(prevContent => ({
          ...prevContent,
          meaning: updatedMeanings,
        }));
        setSelectedMeanings(updatedMeanings);

        alert('解释已更新');
      } catch (error) {
        console.error('Error updating meaning:', error);
        alert('更新解释失败');
      }
    }
  };

  // 使用正则表达式分割每一行的内容，找到英文单词
  const splitLineIntoWords = (lineContent) => {
    const words = lineContent.split(/(\s+)/); // 使用空格分割单词和空格

    return words.map((word, index) => {
      if (/^[a-zA-Z]+$/.test(removePunctuation(word))) {  // 只处理英文单词，排除标点符号
        return (
          <span
            key={index}
            onClick={() => handleWordClick(word)}
            style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }}
          >
            {word}
          </span>
        );
      } else {
        return (
          <span
            key={index}
            style={{ color: 'green' }}
          >
            {word}
          </span>
        );
      }
    });
  };

  const getLineClass = (label) => {
    switch (label) {
      case "美音":
        return "line-american";
      case "发音技巧":
        return "line-pronunciation";
      case "音节分解":
        return "line-syllable";
      default:
        return "line-none";
    }
  };
  
  // 更新行的标记到后端
  const handleUpdateLineContentLabel = async (timestampId, newLabel) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${BACKEND_URL}/update_line_label`,
        {
          filename, // 添加 filename 参数
          timestamp_id: timestampId,
          new_label: newLabel,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // 更新前端状态
      setLines((prevLines) =>
        prevLines.map((line) => {
          if (line.timestamp_id === timestampId) {
            const contentKey = Object.keys(line.content)[0]; // 获取内容
            return {
              ...line,
              content: { [contentKey]: newLabel }, // 更新标记
            };
          }
          return line;
        })
      );
    } catch (error) {
      console.error("Error updating line label:", error);
      alert("更新标记失败，请稍后重试。");
    }
  };
  
  
// 行录音功能
const startLineRecording = async (timestamp_id) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // 检查支持的 MIME 类型
    const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
      ? "audio/mp4"
      : MediaRecorder.isTypeSupported("audio/aac")
      ? "audio/aac"
      : "audio/webm";

    mediaRecorder = new MediaRecorder(stream, { mimeType }); // 设置支持的格式
    audioChunks = [];

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.start();
    console.log("开始录音，使用格式:", mimeType);
  } catch (err) {
    console.error("Error accessing microphone:", err);
    alert("无法访问麦克风，请检查权限设置");
  }
};

const stopLineRecording = async (timestamp_id) => {
  if (mediaRecorder) {
    mediaRecorder.stop();
    mediaRecorder.onstop = async () => {
      const mimeType = mediaRecorder.mimeType;
      const extension = mimeType.includes("mp4") ? "mp4" : mimeType.includes("aac") ? "aac" : "webm";
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const formData = new FormData();
      formData.append("audio", audioBlob, `${timestamp_id}.${extension}`);

      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${BACKEND_URL}/upload_line_audio/${timestamp_id}/${filename}`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        // 更新音频列表
        setLineAudios((prev) => {
          const updatedAudios = { ...prev };
          if (!updatedAudios[timestamp_id]) updatedAudios[timestamp_id] = {};
          updatedAudios[timestamp_id][response.data.filename] = "None";
          return updatedAudios;
        });
      } catch (error) {
        console.error("Error uploading audio:", error);
        alert("录音上传失败");
      }
    };
  }
};

  // 删除音频
  const handleDeleteLineAudio = async (timestamp_id, audioFile) => {
    if (window.confirm(`确定要删除音频 ${audioFile} 吗？`)) {
      try {
        const token = localStorage.getItem("token");
        await axios.delete(
          `${BACKEND_URL}/delete_line_audio/${timestamp_id}/${filename}/${audioFile}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        // 从页面中移除指定音频
        setLineAudios((prevAudios) => {
          const updatedAudios = { ...prevAudios };
          if (updatedAudios[timestamp_id]) {
            delete updatedAudios[timestamp_id][audioFile];
  
            // 如果该行没有剩余音频，删除该行
            if (Object.keys(updatedAudios[timestamp_id]).length === 0) {
              delete updatedAudios[timestamp_id];
            }
          }
          return updatedAudios;
        });
      } catch (error) {
        console.error("Error deleting audio:", error);
        alert("删除音频失败");
      }
    }
  };

// 单词录音功能 - 开始录音
const startWordRecording = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // 检查支持的 MIME 类型并设置
    const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
      ? "audio/mp4"
      : MediaRecorder.isTypeSupported("audio/aac")
      ? "audio/aac"
      : "audio/webm";

    mediaRecorder = new MediaRecorder(stream, { mimeType });
    audioChunks = [];

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.start();
    console.log("开始录音，使用格式:", mimeType);
  } catch (err) {
    console.error("Error accessing microphone:", err);
    alert("无法访问麦克风，请检查权限设置");
  }
};

// 单词录音功能 - 结束录音并上传
const stopWordRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stop();
    const token = localStorage.getItem("token");
    mediaRecorder.onstop = () => {
      const mimeType = mediaRecorder.mimeType;
      const extension = mimeType === "audio/mp4" ? "mp4" : mimeType === "audio/aac" ? "aac" : "webm";
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const formData = new FormData();
      formData.append("audio", audioBlob, `${modalContent.word}_recording.${extension}`);

      fetch(`${BACKEND_URL}/upload_audio/${modalContent.word}`, {
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          const filename = data.filename;

          // 更新音频复选框
          setModalContent((prevContent) => ({
            ...prevContent,
            audioFiles: [
              ...prevContent.audioFiles,
              { filename, label: "None" }, // 新录音的默认标签
            ],
          }));
        })
        .catch((error) => {
          console.error("录音上传失败:", error);
          alert("录音上传失败，请稍后重试。");
        });
    };
  }
};


  // 删除音频
  const handleDeleteAudio = async (audioFile) => {
    if (window.confirm(`确定要删除音频 ${audioFile} 吗？`)) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${BACKEND_URL}/delete_audio/${modalContent.word}/${audioFile}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        // 更新模态框状态
        setModalContent((prevContent) => ({
          ...prevContent,
          audioFiles: prevContent.audioFiles.filter(({ filename }) => filename !== audioFile),
        }));
      } catch (error) {
        console.error('Error deleting audio:', error);
      }
    }
  };
  

  // 增加行的逻辑
  const addLine = async (position, index) => {
    const newContent = prompt("请输入新行的内容：");
    if (!newContent) return;

    const newLine = {
        line_number: maxLineNumber + 1,
        timestamp_id: `line-${maxTimestampId + 1}`,
        content: { [newContent]: "None" }, // 默认标签为 "None"
    };

    const updatedLines = [
        ...lines.slice(0, index + (position === 'after' ? 1 : 0)),
        newLine,
        ...lines.slice(index + (position === 'after' ? 1 : 0))
    ];

    // 更新数据库
    await saveUpdatedLines(updatedLines);
    setLines(updatedLines);
    setMaxLineNumber(maxLineNumber + 1);
    setMaxTimestampId(maxTimestampId + 1);
  };

  // 修改行的逻辑
  const editLine = async (index) => {
    const currentContent = Object.keys(lines[index].content)[0]; // 获取 content 的键
    const newContent = prompt("修改本行内容：", currentContent); // 填入键值作为默认值
    if (!newContent) return;

    const updatedLines = [...lines];
    const currentTag = Object.values(lines[index].content)[0]; // 获取当前标签
    updatedLines[index].content = { [newContent]: currentTag || "None" }; // 更新为对象格式

    // 更新数据库
    await saveUpdatedLines(updatedLines);
    setLines(updatedLines);
  };


  // 删除行的逻辑
  const deleteLine = async (index) => {
    if (!window.confirm("确定要删除本行吗？")) return;

    const updatedLines = lines.filter((_, i) => i !== index);

    // 更新数据库
    await saveUpdatedLines(updatedLines);
    setLines(updatedLines);
  };

  // 保存更新后的 lines 到数据库
  const saveUpdatedLines = async (updatedLines) => {
    try {
      const token = localStorage.getItem('token');
      const new_data = updatedLines.map(line => JSON.stringify(line))
      console.log('updatedLines:', updatedLines);
      await axios.post(`${BACKEND_URL}/update_lines/${filename}`, {
        lines: updatedLines,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error("Error updating lines:", error);
    }
  };

  // 切换播放/暂停状态的函数
  const togglePronunciationAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleUpdateAudioLabel = async (audioFile, newLabel) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${BACKEND_URL}/update_audio_label/${modalContent.word}`,
        { filename: audioFile, new_label: newLabel },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // 更新模态框状态
      setModalContent((prevContent) => ({
        ...prevContent,
        audioFiles: prevContent.audioFiles.map((file) =>
          file.filename === audioFile
            ? { ...file, label: newLabel }
            : file
        ),
      }));
    } catch (error) {
      console.error("Error updating audio label:", error.response?.data || error.message);
    }
  };

  const handleUploadAudio = async (event, timestamp_id) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("audio", file);
  
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BACKEND_URL}/upload_audio/${timestamp_id}/${filename}`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // 更新前端音频列表
      setLineAudios((prev) => {
        const updatedAudios = { ...prev };
        if (!updatedAudios[timestamp_id]) updatedAudios[timestamp_id] = {};
        updatedAudios[timestamp_id][response.data.filename] = "None";
        return updatedAudios;
      });
  
      alert("音频上传成功！");
    } catch (error) {
      console.error("Error uploading audio:", error);
      alert("音频上传失败，请重试。");
    }
  };

  const handleUploadWordAudio = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("audio", file);
  
    try {
      const response = await fetch(`${BACKEND_URL}/upload_audio_file/${modalContent.word}`, {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const filename = data.filename;
  
      // 更新音频复选框
      setModalContent((prevContent) => ({
        ...prevContent,
        audioFiles: [
          ...prevContent.audioFiles,
          { filename, label: "None" }, // 新上传音频的默认标签
        ],
      }));
    } catch (error) {
      console.error("上传录音失败:", error);
      alert("上传录音失败，请稍后重试。");
    }
  };
  const handleUpdateLineAudioLabel = async (timestamp_id, audioFile, newLabel) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${BACKEND_URL}/update_line_audio_label/${timestamp_id}/${filename}`,
        { filename: audioFile, new_label: newLabel },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      setLineAudios((prev) => {
        const updatedAudios = { ...prev };
        if (updatedAudios[timestamp_id]) {
          updatedAudios[timestamp_id][audioFile] = newLabel;
        }
        return updatedAudios;
      });
    } catch (error) {
      console.error("Error updating audio label:", error);
    }
  };
  
  // 在音频加载完成后更新音频时长
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadedmetadata = () => {
        const duration = audioRef.current.duration;
        setAudioDuration(formatTime(duration));
      };
    }
  }, []);

  // 将音频秒数转换为“分钟:秒”格式
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div>
      <h1>详解</h1>
      <div>
        {lines.map((line, index) => {
          const [contentText, currentLabel] = Object.entries(line.content)[0]; // 提取内容和标记

          return (
            <div key={index} className={getLineClass(currentLabel)}>
              {/* 显示行内容 */}
              <p style={{ whiteSpace: "pre-wrap" }}>{splitLineIntoWords(contentText)}</p>

              {/* 修改标记的下拉框 */}
              <div>
                <label>标记:</label>
                <select
                  value={currentLabel}
                  onChange={(e) =>
                    handleUpdateLineContentLabel(line.timestamp_id, e.target.value)
                  }
                >
                  <option value="None">None</option>
                  <option value="美音">美音</option>
                  <option value="发音技巧">发音技巧</option>
                  <option value="音节分解">音节分解</option>
                </select>
              </div>

              {contentText.trim() !== "" && (
                <div>
                  {/* 行录音按钮 */}
                  <div className="recording-controls">
                    <input
                      type="file"
                      accept="audio/*"
                      onChange={(e) => handleUploadAudio(e, line.timestamp_id)}
                      style={{ marginBottom: "10px" }}
                    />
                    <button
                      id={line.timestamp_id}
                      onMouseDown={() => {
                        document.getElementById(line.timestamp_id).innerText =
                          "录音中...";
                        startLineRecording(line.timestamp_id);
                      }}
                      onMouseUp={() => {
                        document.getElementById(line.timestamp_id).innerText =
                          "按住录音";
                        stopLineRecording(line.timestamp_id);
                      }}
                    >
                      按住录音
                    </button>
                    <div>
                      {Object.entries(lineAudios[line.timestamp_id] || {}).map(
                        ([audio, label]) => (
                          <div key={audio} className="audio-item">
                            <audio
                              controls
                              src={`${SHORT_URL}/audio/${audio}?t=${Date.now()}`}
                            />
                            <button
                              onClick={() =>
                                handleDeleteLineAudio(line.timestamp_id, audio)
                              }
                            >
                              删除
                            </button>
                            <select
                              value={label}
                              onChange={(e) =>
                                handleUpdateLineAudioLabel(
                                  line.timestamp_id,
                                  audio,
                                  e.target.value
                                )
                              }
                            >
                              <option value="None">None</option>
                              <option value="美音">美音</option>
                              <option value="发音技巧">发音技巧</option>
                              <option value="音节分解">音节分解</option>
                            </select>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="line-actions">
                    <button onClick={() => addLine("before", index)}>
                      在前面增加行
                    </button>
                    <button onClick={() => addLine("after", index)}>
                      在后面增加行
                    </button>
                    <button onClick={() => editLine(index)}>修改本行</button>
                    <button
                      className="delete"
                      onClick={() => deleteLine(index)}
                    >
                      删除本行
                    </button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>;

  {/* 模态框 */}
  {modalVisible && (
    <div className="modal">
      <div className="modal-content">
        <h2>{modalContent.word}</h2>
        <p>
          音标:
          <input value={pronunciation} onChange={(e) => setPronunciation(e.target.value)} />
        </p>

        <label>解释：</label>
        <select multiple value={selectedMeanings} onChange={handleMeaningChange}>
          {modalContent.meaning.map((meaning, i) => (
            <option key={i} value={meaning}>{meaning}</option>
          ))}
        </select>
        <div>已选择的解释: {selectedMeanings.join("; ")}</div>

        <label>上传录音：</label>
        <input
          type="file"
          accept="audio/*"
          onChange={handleUploadWordAudio}
        />

        <label>音频：</label>
        <select multiple value={selectedAudios} onChange={handleAudioChange}>
          {modalContent.audioFiles.map(({ filename }, i) => (
            <option key={filename} value={filename}>
              {filename}
            </option>
          ))}
        </select>
        <div>
          已选择的音频:
          {selectedAudios.map((audio) => {
            const label = modalContent.audioFiles.find(({ filename }) => filename === audio)?.label || "None";
            return (
              <div key={audio} className="audio-item">
                <audio controls src={`${SHORT_URL}/audio/${audio}?t=${Date.now()}`} className="audio-control" />
                <button onClick={() => handleDeleteAudio(audio)} className="delete-button">删除</button>
                <select
                  value={label}
                  onChange={(e) => handleUpdateAudioLabel(audio, e.target.value)}
                >
                  <option value="None">None</option>
                  <option value="美音">美音</option>
                  <option value="发音技巧">发音技巧</option>
                  <option value="音节分解">音节分解</option>
                </select>
              </div>
            );
          })}
        </div>

        {/* 单词录音按钮 */}
        <button
          className="record-button"
          id={modalContent.word}
          onMouseDown={() => {
            document.getElementById(modalContent.word).innerText = "录音中...";
            startWordRecording();
          }}
          onMouseUp={() => {
            document.getElementById(modalContent.word).innerText = "按住录音";
            stopWordRecording();
          }}
        >
          按住录音
        </button>

        <button className="modify-button" onClick={handleModifyMeaning}>修改解释</button>
        <button className="save-button" onClick={handleSave}>保存</button>
        <button className="close-button" onClick={() => setModalVisible(false)}>关闭</button>
      </div>
    </div>
  )}


    </div>
  );
}

export default DetailedPage;
