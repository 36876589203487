import React, { useState } from "react";
import axios from "axios";

function LearningSkill() {
  const [file, setFile] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("请选择一个文件！");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${BACKEND_URL}/picupload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert(`文件上传成功: ${response.data.filename}`);
    } catch (error) {
      console.error("文件上传失败", error);
      alert("文件上传失败，请检查后端配置！");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>上传文件测试</h1>
      <input type="file" onChange={handleFileChange} accept="image/*" />
      <button onClick={handleUpload} style={{ marginLeft: "10px" }}>
        上传文件
      </button>
    </div>
  );
}

export default LearningSkill;
