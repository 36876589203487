import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DetailedPage.css';

function WordListPage({ filename }) {
  const [words, setWords] = useState([]);
  const [wordDetails, setWordDetails] = useState({});
  const [savedSelections, setSavedSelections] = useState({});
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const SHORT_URL = BACKEND_URL.split("/api")[0];
  // 获取单词列表和用户保存的选择
  useEffect(() => {
    const fetchWordData = async () => {
      try {
        const token = localStorage.getItem('token');
    
        // 获取单词列表和用户选择
        const response = await axios.get(`${BACKEND_URL}/word_list/${filename}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const fetchedWords = response.data.words;
        setWords(fetchedWords);
        setSavedSelections(response.data.saved_selections || {});
    
        if (fetchedWords.length > 0) {
          // 使用批量接口一次性获取所有单词详情
          const batchResponse = await axios.post(
            `${BACKEND_URL}/batch_word_details`,
            { words: fetchedWords }, // 确保发送的格式为 { words: [...] }
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setWordDetails(batchResponse.data.details || {});
        }
      } catch (error) {
        console.error('Error fetching word list or details:', error);
      }
    };
    fetchWordData();
  }, [filename]);
  

  const handleSaveChanges = async (word, updatedSelections) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${BACKEND_URL}/save_selections/${filename}`, {
        word,
        selections: updatedSelections,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // 更新本地的保存状态
      setSavedSelections(prevSelections => ({
        ...prevSelections,
        [word]: updatedSelections,
      }));
    } catch (error) {
      console.error('Error saving selections:', error);
    }
  };

  const handleAddWordBefore = (currentWord) => {
    const newWord = prompt('请输入要在该单词前增加的新单词:');
    if (newWord && !words.includes(newWord)) {
      const index = words.indexOf(currentWord);
      const newWords = [...words];
      newWords.splice(index, 0, newWord);
      setWords(newWords);
      fetchWordDetails(newWord);
      saveWordList(newWords); // 保存更新后的单词列表
    }
  };

  const handleAddWordAfter = (currentWord) => {
    const newWord = prompt('请输入要在该单词后增加的新单词:');
    if (newWord && !words.includes(newWord)) {
      const index = words.indexOf(currentWord);
      const newWords = [...words];
      newWords.splice(index + 1, 0, newWord);
      setWords(newWords);
      fetchWordDetails(newWord);
      saveWordList(newWords); // 保存更新后的单词列表
    }
  };

  const handleDeleteWord = (word) => {
    if (window.confirm(`确定要删除单词 "${word}" 吗？`)) {
      const newWords = words.filter(w => w !== word);
      setWords(newWords);
      setWordDetails(prevDetails => {
        const { [word]: _, ...remainingDetails } = prevDetails;
        return remainingDetails;
      });
      saveWordList(newWords); // 保存删除后的单词列表
    }
  };

  const handleEditWord = (currentWord) => {
    const newWord = prompt('请输入新的单词:', currentWord);
    if (newWord && newWord !== currentWord) {
      const newWords = words.map(w => (w === currentWord ? newWord : w));
      setWords(newWords);
      fetchWordDetails(newWord);
      saveWordList(newWords); // 保存修改后的单词列表
    }
  };

  const fetchWordDetails = async (word) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/word_details/${word}`);
      setWordDetails(prevDetails => ({
        ...prevDetails,
        [word]: response.data,
      }));
    } catch (error) {
      console.error('Error fetching word details:', error);
    }
  };

  const saveWordList = async (updatedWords) => {
    try {
      const token = localStorage.getItem('token');
      // 保存更新后的单词列表到数据库
      await axios.post(`${BACKEND_URL}/save_word_list/${filename}`, {
        words: updatedWords,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error('Error saving word list:', error);
    }
  };

  return (
    <div>
      <h1>单词本</h1>
      <ul>
        {words.length === 0 && <p>加载中...</p>}
        {words.map((word, index) => (
          <WordDetails
            key={index}
            word={word}
            savedSelections={savedSelections[word]}
            wordDetails={wordDetails[word]}
            onSave={handleSaveChanges}
            onAddBefore={handleAddWordBefore}
            onAddAfter={handleAddWordAfter}
            onDelete={handleDeleteWord}
            onEdit={handleEditWord}
            fetchWordDetails={fetchWordDetails}  // 传递给子组件
            setWordDetails={setWordDetails}     // 传递 setWordDetails 给子组件
          />
        ))}
      </ul>
    </div>
  );
}

function WordDetails({ word, savedSelections, wordDetails, onSave, onAddBefore, onAddAfter, onDelete, onEdit, fetchWordDetails, setWordDetails }) {
  const [pronunciation, setPronunciation] = useState('');
  const [meanings, setMeanings] = useState([]);
  const [audios, setAudios] = useState([]);
  const [isEditingMeaning, setIsEditingMeaning] = useState(false); // 控制是否在编辑解释
  const [newMeaning, setNewMeaning] = useState(''); // 存储修改后的解释
  const [isRecording, setIsRecording] = useState(false); // 控制录音状态
  let mediaRecorder = null;
  let audioChunks = [];
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const SHORT_URL = BACKEND_URL.split("/api")[0];
  // 初始化数据
  useEffect(() => {
    if (wordDetails) {
      setPronunciation(savedSelections?.pronunciation || wordDetails.pronunciation || '');
      setMeanings(savedSelections?.selected_meanings?.split(';') || []); // 保留字符串逻辑
      // 从字典中提取音频文件列表
      const audioFiles = savedSelections?.selected_audios
        ? Object.keys(savedSelections.selected_audios) // 提取字典的键
        : [];
      setAudios(audioFiles); // 初始化音频列表
    }
  }, [wordDetails, savedSelections]);

  const handlePronunciationChange = (event) => {
    setPronunciation(event.target.value);
  };

  const handleMeaningChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(opt => opt.value);
    setMeanings(selectedOptions);
  };

  const handleAudioChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(opt => opt.value);
    setAudios(selectedOptions); // 更新选中的音频列表
  };

  const handleSave = () => {
    const updatedSelections = {
      pronunciation,
      selected_meanings: meanings.join(';'),
      selected_audios: audios.reduce((acc, audio) => {
        acc[audio] = audioLabels[audio] || "None"; // 将每个音频文件与其标签关联
        return acc;
      }, {}), // 将 `audios` 转换为字典格式
    };
  
    onSave(word, updatedSelections); // 调用父组件传递的保存方法
  };

  // 上传音频功能
  const handleUploadAudio = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("audio", file);

    try {
      const response = await fetch(`${BACKEND_URL}/upload_audio_file/${word}`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const filename = data.filename;

      // 更新音频列表
      setWordDetails((prevDetails) => ({
        ...prevDetails,
        [word]: {
          ...prevDetails[word],
          audio_files: {
            ...(prevDetails[word]?.audio_files || {}),
            [filename]: "None", // 新上传音频的默认标签
          },
        },
      }));
    } catch (error) {
      console.error("上传音频失败:", error);
      alert("上传音频失败，请稍后重试。");
    }
  };

  // 开始录音
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // 检查支持的 MIME 类型并设置
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : MediaRecorder.isTypeSupported("audio/aac")
        ? "audio/aac"
        : "audio/webm";

      mediaRecorder = new MediaRecorder(stream, { mimeType });
      audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };
      mediaRecorder.start();
      console.log("开始录音，使用格式:", mimeType);
    } catch (err) {
      console.error("Error accessing microphone:", err);
      alert("无法访问麦克风，请检查权限设置");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        const mimeType = mediaRecorder.mimeType;
        const extension = mimeType === "audio/mp4" ? "mp4" : mimeType === "audio/aac" ? "aac" : "webm";
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        const formData = new FormData();
        formData.append("audio", audioBlob, `${word}_recording.${extension}`);
  
        fetch(`${BACKEND_URL}/upload_audio/${word}`, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            const filename = data.filename;
  
            // 更新音频列表和页面状态
            setWordDetails((prevDetails) => ({
              ...prevDetails,
              [word]: {
                ...prevDetails[word],
                audio_files: {
                  ...(prevDetails[word]?.audio_files || {}),
                  [filename]: "None", // 新录音的默认标签
                },
              },
            }));
          })
          .catch((error) => {
            console.error("录音上传失败:", error);
            alert("录音上传失败，请稍后重试。");
          });
      };
    }
  };
  
  const handleDeleteAudio = async (audioFile) => {
    if (window.confirm(`确定要删除音频 ${audioFile} 吗？`)) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${BACKEND_URL}/delete_audio/${word}/${audioFile}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        // 删除成功后更新页面状态
        setWordDetails((prevDetails) => {
          const updatedAudioFiles = { ...prevDetails[word]?.audio_files };
          delete updatedAudioFiles[audioFile]; // 删除对应键
  
          // 同步更新音频文件列表
          const updatedAudioList = Object.keys(updatedAudioFiles);
  
          return {
            ...prevDetails,
            [word]: {
              ...prevDetails[word],
              audio_files: updatedAudioFiles, // 更新对象
              audios: updatedAudioList, // 更新可用音频列表
            },
          };
        });
      } catch (error) {
        console.error('Error deleting audio:', error);
      }
    }
  };
  
  const handleUpdateAudioLabel = async (audioFile, newLabel) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${BACKEND_URL}/update_audio_label/${word}`,
        { filename: audioFile, new_label: newLabel },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // 更新本地状态
      setWordDetails(prevDetails => ({
        ...prevDetails,
        [word]: {
          ...prevDetails[word],
          audio_files: {
            ...(prevDetails[word]?.audio_files || {}),
            [audioFile]: newLabel, // 更新对应音频的标签
          },
        },
      }));
    } catch (error) {
      console.error("Error updating audio label:", error.response?.data || error.message);
    }
  };
  
  // 处理修改解释
  const handleEditMeaning = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${BACKEND_URL}/update_meaning/${word}`, {
        new_meaning: newMeaning,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // 成功后，重新获取单词详细信息
      fetchWordDetails(word);
      setIsEditingMeaning(false); // 关闭编辑模式
    } catch (error) {
      console.error('Error updating meaning:', error);
    }
  };

  const availableMeanings = wordDetails?.meaning?.split(';') || [];
  const availableAudios = wordDetails?.audio_files ? Object.keys(wordDetails.audio_files) : [];
  const audioLabels = wordDetails?.audio_files || {}; // 如果不存在，则初始化为空对象

  return (
    <li>
      <h2>{word}</h2>
      <label>音标：</label>
      <input
        type="text"
        value={pronunciation}
        onChange={handlePronunciationChange}
      />
      <br />
  
      <label>解释：</label>
      <select multiple value={meanings} onChange={handleMeaningChange}>
        {availableMeanings.map((meaning, index) => (
          <option key={index} value={meaning}>
            {meaning}
          </option>
        ))}
      </select>
      <div>已选择的解释: {meanings.join("; ")}</div>
      <br />
  
      <label>上传音频：</label>
      <input type="file" accept="audio/*" onChange={handleUploadAudio} />
      <br />
  
      <label>音频：</label>
      <select multiple value={audios} onChange={handleAudioChange}>
        {availableAudios.map((audio, index) => (
          <option key={index} value={audio}>
            {audio}
          </option>
        ))}
      </select>
      <div>已选择的音频:
        {audios.map(audio => (
          <div key={audio}>
            <audio controls src={`${SHORT_URL}/audio/${audio}?t=${new Date().getTime()}`} />
            <button onClick={() => handleDeleteAudio(audio)}>删除音频</button>
            {/* 标签选择框 */}
            <select
              value={audioLabels[audio] || "None"}
              onChange={(e) => handleUpdateAudioLabel(audio, e.target.value)}
            >
              <option value="None">None</option>
              <option value="美音">美音</option>
              <option value="发音技巧">发音技巧</option>
              <option value="音节分解">音节分解</option>
            </select>
          </div>
        ))}
      </div>
  
      {/* 录音按钮 */}
      <button
        id={word}
        onMouseDown={() => {
          document.getElementById(word).innerText = "录音中...";
          startRecording();
        }}
        onMouseUp={() => {
          document.getElementById(word).innerText = "按住录音";
          stopRecording();
        }}
      >
        按住录音
      </button>
  
      <button onClick={handleSave}>保存</button>
      <button onClick={() => onAddBefore(word)}>在本单词前增加</button>
      <button onClick={() => onAddAfter(word)}>在本单词后增加</button>
      <button onClick={() => onDelete(word)}>删除单词</button>
      <button onClick={() => onEdit(word)}>修改单词</button>
  
      {/* 修改解释按钮 */}
      {!isEditingMeaning ? (
        <button onClick={() => setIsEditingMeaning(true)}>修改解释</button>
      ) : (
        <div>
          <textarea
            value={newMeaning}
            onChange={(e) => setNewMeaning(e.target.value)}
          />
          <button onClick={handleEditMeaning}>确定</button>
          <button onClick={() => setIsEditingMeaning(false)}>取消</button>
        </div>
      )}
    </li>
  );
}
export default WordListPage;