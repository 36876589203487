import React, { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import AdminPage from './AdminPage';
import CourseEditor from './CourseEditor';
import StudyWords from './StudyWords';
import MyCourses from './MyCourses'; 
import WordOverview from './WordOverview'; 
import StudentPage from './StudentPage';
import ChooseTeacherPage from './ChooseTeacherPage';
import MyMessagesPage from './MyMessagesPage';
import './setupAxios';
import { setupAxiosInterceptors } from './setupAxios';
import LearningSkill from './LearningSkill';
import Home from './Home';
import UserProfilePage from './UserProfilePage'; // 导入新的个人用户页面组件
import InvitationPage from './InvitationPage'; // 导入邀请码页面组件
import WordSelection from './WordSelection';
import './App.css';

function App() {
  const [userAvatar, setUserAvatar] = useState(localStorage.getItem("userAvatar") || "default.png");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');
  const [wordListPage, setWordListPage] = useState(null);
  const [allWords, setAllWords] = useState(null);
  const [synSelections, setSynSelections] = useState(null);
  const [userId, setUserId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [newMessage, setNewMessage] = useState(false);
  const [messages, setMessages] = useState([]);  // 保存消息的状态
  const ws = useRef(null);  // WebSocket 用 useRef 来管理连接
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userNickname, setUserNickname] = useState(""); // 存储用户昵称
  const menuRef = useRef(null); // 用于检测点击位置的引用
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const SHORT_URL = BACKEND_URL.split("/api")[0];
  // 初始化 axios 拦截器并传入 setIsLoggedIn 和 setIsModalOpen
  useEffect(() => {
    setupAxiosInterceptors(setIsLoggedIn, setIsModalOpen);
    const savedNickname = localStorage.getItem("nickname");
    if (savedNickname) {
      setUserNickname(savedNickname);
    }
  }, []);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    const userRole = localStorage.getItem('userRole');
    const savedPage = localStorage.getItem('currentPage');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
      if (userRole === 'superadmin') {
        setIsSuperAdmin(true);
      }
    }
    if (savedPage) {
      setCurrentPage(savedPage);
    }
  }, []);

  // 确保 WebSocket 连接在登录后建立并持续存在
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    setUserId(userId);
    const userRole = localStorage.getItem('userRole');

    if (userRole && userId && isLoggedIn) {
      if (!ws.current) {  // 如果当前没有 WebSocket 连接
        const websocket = new WebSocket(`${BACKEND_URL.replace('http', 'ws')}/ws/${userId}`);
        ws.current = websocket;

        websocket.onmessage = (event) => {
          const receivedMessage = JSON.parse(event.data);
          console.log("New message received:", receivedMessage);
          setMessages((prevMessages) => [...prevMessages, receivedMessage]);  // 更新全局的消息状态
          setNewMessage(true);  // 当收到新消息时更新 newMessage 状态
        };

        websocket.onclose = () => {
          console.log("WebSocket connection closed");
          ws.current = null;  // 连接关闭时将 ws 设为 null
        };

        websocket.onerror = (error) => {
          console.error("WebSocket error:", error);
        };
      }
    }

    return () => {
      if (ws.current) {
        ws.current.close();  // 在组件卸载时关闭 WebSocket 连接
      }
    };
  }, [isLoggedIn]);  // 当登录状态改变时，重新建立 WebSocket 连接

  // 点击页面其他位置关闭菜单的事件监听器
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false); // 如果点击发生在菜单外部，则关闭菜单
      }
    };

    // 只有菜单打开时才添加事件监听器
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // 清理事件监听器
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  // 在登出时清理 WebSocket 连接
  const handleLoginClick = () => {
    if (isLoggedIn) {
      const confirmed = window.confirm("确定要退出登录吗?");
      if (confirmed) {
        setIsLoggedIn(false);
        setIsSuperAdmin(false);
        setCurrentPage('home');
        setIsMenuOpen(false); // 关闭头像菜单
        localStorage.clear();

        // 关闭 WebSocket 连接
        if (ws.current) {
          ws.current.close();
          ws.current = null;  // 关闭连接后清理 ws 引用
        }
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const handleLoggedIn = (status, role, avatar, nickname) => {
    setIsLoggedIn(status);
    setUserNickname(nickname);  // 设置昵称
    setUserAvatar(avatar);
    localStorage.setItem('isLoggedIn', status ? 'true' : 'false');
    localStorage.setItem('userRole', role);
    localStorage.setItem('userAvatar', avatar);  // 存储头像到 localStorage
    localStorage.setItem('nickname', nickname);  // 存储昵称到 localStorage
    if (role === 'superadmin') {
      setIsSuperAdmin(true);
    }
  };
  

  // 切换到“我的消息”页面时传递消息
  const handleMyMessagesClick = () => {
    setCurrentPage('my-messages');
    setNewMessage(false);  // 清除新消息提示，但保持 WebSocket 连接活跃
  };

  const renderPage = () => {
    if (currentPage === 'home') {
      return <Home setCurrentPage={setCurrentPageAndSave} userId={userId} />; // 将 setCurrentPage 传递给 Home
    } else if (currentPage === 'user-profile') {
      return <UserProfilePage userId={userId} setUserAvatar={setUserAvatar} setUserNickname={setUserNickname} />;
    } else if (currentPage === 'invitation') {  // 添加邀请码页面
      return <InvitationPage userId={userId} />;
    } else if (currentPage === 'admin') {
      return <AdminPage />;
    } else if (currentPage === 'word-selection') {
      return <WordSelection />;
    } else if (currentPage === 'student-page') {
      return <StudentPage fileName={fileName} userId={userId} />; 
    } else if (currentPage === 'word-overview') {
      return <WordOverview fileName={fileName} wordListPage={wordListPage} userId={userId} />;
    } else if (currentPage === 'my-messages') {
      return <MyMessagesPage fileName={null} messages={messages} userId={userId} />;  // 传递消息到 MyMessagesPage
    } else if (currentPage === 'course-editor') {
      return <CourseEditor setCurrentPage={setCurrentPage} setAllWords={setAllWords} setWordListPage={setWordListPage} setSynSelections={setSynSelections} />;
    } else if (currentPage === 'choose-teacher') {
      return <ChooseTeacherPage />;
    } else if (currentPage === 'learning-skill') {
      return <LearningSkill userId={userId}/>;
    } else if (currentPage === 'study-words') {
      return <StudyWords fileName={fileName} wordListPage={wordListPage} userId={userId} />;
    } else if (currentPage === 'my-courses') { 
      return <MyCourses setCurrentPage={setCurrentPage} setWordListPage={setWordListPage} setFileName={setFileName}/>;
    }
  };

  const setCurrentPageAndSave = (page) => {
    setCurrentPage(page);
    localStorage.setItem('currentPage', page);
  };
  const userRole = localStorage.getItem('userRole');  

  return (
    <div className="App container">
      <header className="App-header">
        <nav>
          <ul className="nav-links">
            <li><a href="#" onClick={() => setCurrentPageAndSave('home')}>我的主页</a></li>
            <li><a href="#" onClick={() => setCurrentPageAndSave('my-courses')}>我的课程</a></li>
            <li><a href="#" onClick={() => setCurrentPageAndSave('learning-skill')}>学习方法</a></li>
            <li><a href="#" onClick={() => setCurrentPageAndSave('home')}>会员订阅</a></li>
            <li>
              <a href="#" onClick={handleMyMessagesClick}>
                我的消息 {newMessage && <span style={{ color: 'red' }}>（新）</span>}
              </a>
            </li>
            {isSuperAdmin && <li><a href="#" onClick={() => setCurrentPageAndSave('course-editor')}>编写课程</a></li>}
            {userRole === 'student' && (
              <li><a href="#" onClick={() => setCurrentPageAndSave('choose-teacher')}>选择老师</a></li>
            )}
            {isSuperAdmin && <li><a href="#" onClick={() => setCurrentPageAndSave('admin')}>管理</a></li>}
            {isSuperAdmin && <li><a href="#" onClick={() => setCurrentPageAndSave('word-selection')}>单词选项</a></li>}
            <li className="login-button">
              {isLoggedIn ? (
                <div className="avatar-menu-container" ref={menuRef}>
                  <img
                    src={`${SHORT_URL}/Userpics/${localStorage.getItem("userAvatar") || "default.png"}`}
                    alt="User Avatar"
                    className="avatar"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    style={{ borderRadius: "50%", width: "40px", height: "40px", cursor: "pointer" }}
                  />
                  {isMenuOpen && (
                      <div className="menu-dropdown">
                          <p>{userNickname}</p>
                          <hr />
                          <button onClick={() => setCurrentPageAndSave('user-profile')}>我的主页</button>
                          <button onClick={handleLoginClick}>退出</button>
                      </div>
                  )}

                </div>
              ) : (
                <button onClick={() => setIsModalOpen(true)}>登录</button>
              )}
            </li>
          </ul>
        </nav>
      </header>
      <main>
        {renderPage()} 
      </main>
      {isModalOpen && <Modal setIsModalOpen={setIsModalOpen} setIsLoggedIn={handleLoggedIn} />}
    </div>
  );
}

export default App;
