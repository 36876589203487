import React from 'react';

export const QuestionButton = ({
  timestampId,
  questions,
  isQuestionExpanded,
  isAskingQuestion,
  handleQuestionToggle,
}) => (
  <button
    onClick={() => handleQuestionToggle(timestampId)}
  >
    {questions[timestampId]
      ? isQuestionExpanded[timestampId] ? '收起提问' : '展开提问'
      : isAskingQuestion[timestampId] ? '关闭提问' : '提问'}
  </button>
);
