import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useQuestions } from './useQuestions';
import { QuestionButton } from './QuestionButton';
import { QuestionForm } from './QuestionForm';
import { AskQuestions } from './AskQuestions';

// 获取今天的日期（格式：YYYY-MM-DD）
const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; // 只保留日期部分
};

function StudyWords({ fileName, wordListPage, userId }) {
  const [words, setWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedReviewCount, setDisplayedReviewCount] = useState(0);
  const [seenToday, setSeenToday] = useState(new Set());
  const [remainingWordsCount, setRemainingWordsCount] = useState(0);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);  // 用于播放未提交的录音
  const [submittedAudioUrl, setSubmittedAudioUrl] = useState(null);  // 提交后的音频文件
  const [unsavedRecording, setUnsavedRecording] = useState(false); // 标记是否有未提交的录音
  const [preloadCount, setPreloadCount] = useState(1); // 默认预加载1个音频
  const [audioCache, setAudioCache] = useState({}); // 缓存音频对象
  const [incorrectOptionsCache, setIncorrectOptionsCache] = useState({});
  const [showExplanation, setShowExplanation] = useState(false);
  const [choices, setChoices] = useState([]);
  const [correctChoice, setCorrectChoice] = useState("");
  const [timer, setTimer] = useState(0);
  const [showChoices, setShowChoices] = useState(false);
  const [audioFiles, setAudioFiles] = useState([]); // 当前单词的音频文件数组
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const SHORT_URL = BACKEND_URL.split("/api")[0];
  const todayDate = getTodayDate();  // 获取今天的日期
  const needTeacher = true;
  const {
    questions,
    isAskingQuestion,
    isQuestionExpanded,
    questionText,
    setQuestionText,
    questionAudio,
    setQuestionAudio,
    handleQuestionToggle,
    handleSubmitQuestion,
    fetchQuestions,
    startQuestionRecording,
    stopQuestionRecording,
    remainingPoints,
    estimatedPoints,
    handleTextChange,
    renderAudio,
    pendingAudio,
    followupText,
    handleSubmitFollowup,
    setFollowupText,
    setAssignTeacher,
    assignTeacher,
  } = useQuestions(fileName, userId, needTeacher);
  let mediaRecorder = null;
  let audioChunks = [];

  // 从 token 中获取用户 ID
  const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      console.log('decodedToken:', decodedToken);
      return decodedToken.id;
    }
    return null;
  };

  useEffect(() => {
    fetchQuestions();
  }, []);  // [] 表示只在组件挂载时执行

  useEffect(() => {
    fetchWords();
  }, [wordListPage]);

  const getUTCOffset = () => {
    const offsetMinutes = new Date().getTimezoneOffset(); // 返回以分钟为单位的偏移量
    const offsetHours = -offsetMinutes / 60; // 转化为小时，注意正负号
    return `UTC${offsetHours >= 0 ? "+" : ""}${offsetHours}`;
  };
  
  const fetchWords = async () => {
    const utcOffset = getUTCOffset(); // 获取用户本地的 UTC 偏移
    console.log("User UTC Offset:", utcOffset);
  
    try {
      console.log('userId:', userId);
  
      // 在请求 URL 中添加偏移量参数
      const response = await axios.get(
        `${BACKEND_URL}/get_sorted_words/${userId}/${wordListPage}`,
        { params: { offset: utcOffset } } // 使用 params 传递查询参数
      );
  
      const fetchedWords = response.data;
      const unreviewedWords = fetchedWords.filter(word => !seenToday.has(word.word)); // 今天未背过的单词
      setWords(unreviewedWords);
      setRemainingWordsCount(unreviewedWords.length - 1);
  
      // 检查当前单词是否有提交的录音
      if (unreviewedWords[currentIndex] && unreviewedWords[currentIndex].submitted_audio) {
        setSubmittedAudioUrl(unreviewedWords[currentIndex].submitted_audio);
      }
  
      console.log('fetchedWords[0]:', fetchedWords[0]);
      if (fetchedWords[0]) {
        // 设置第一个单词的音频（处理 audio_files 对象）
        const firstWordAudioFiles = fetchedWords[0]?.audio_files
          ? Object.entries(fetchedWords[0].audio_files).map(([filename, label]) => ({
              filename,
              label,
            }))
          : [];
        setAudioFiles(firstWordAudioFiles);
      }
    } catch (error) {
      console.error("Error fetching words!", error);
    }
  };

  // 预加载音频
  const preloadAudio = (audioFiles, word) => {
    const newCache = { ...audioCache };
  
    audioFiles.forEach(({ filename }) => {
      const audioUrl = `${SHORT_URL}/audio/${filename}?t=${new Date().getTime()}`;
  
      if (!newCache[audioUrl]) {
        const audio = new Audio(audioUrl);
        audio.preload = 'auto';
  
        audio.addEventListener('canplaythrough', () => {
          console.log(`音频预加载完成: ${audioUrl} （单词: ${word || '未定义'}）`);
        });
  
        audio.addEventListener('error', (e) => {
          console.error(`音频加载失败: ${audioUrl}`, e);
        });
  
        newCache[audioUrl] = audio; // 缓存音频对象
      }
    });
  
    setAudioCache(newCache); // 更新缓存
  }; 

  useEffect(() => {
    if (words.length > 0) {
      // 当前单词的音频
      const currentAudioFiles = words[currentIndex]?.audio_files
        ? Object.entries(words[currentIndex].audio_files).map(([filename, label]) => ({ filename, label }))
        : [];
      setAudioFiles(currentAudioFiles);

      // 预加载接下来的单词
      const preloadRange = words.slice(currentIndex + 1, currentIndex + 1 + 5); // 限制最多预加载5个单词
      preloadRange.forEach((word) => {
        const wordAudioFiles = word.audio_files
          ? Object.entries(word.audio_files)
              .slice(0, preloadCount) // 根据用户选择只加载前 preloadCount 个音频
              .map(([filename, label]) => ({ filename, label }))
          : [];
        preloadAudio(wordAudioFiles, word.word || "未定义"); // 确保传递单词名称
      });      
    }
  }, [currentIndex, words, preloadCount]);
  
  const fetchIncorrectOptions = async () => {
    if (!incorrectOptionsCache[wordListPage]) {
      try {
        const response = await axios.get(`${BACKEND_URL}/get_incorrect_options/${wordListPage}`);
        setIncorrectOptionsCache(response.data); // Cache incorrect options for later use
      } catch (error) {
        console.error("Error fetching incorrect options!", error);
      }
    }
  };

  // 切换单词时
  useEffect(() => {
    if (words.length > 0) {
      const currentWord = words[currentIndex]?.word;
      getReviewCount(currentIndex);
  
      // 如果有提交的录音，加载提交的录音URL
      if (words[currentIndex]?.submitted_audio) {
        setSubmittedAudioUrl(words[currentIndex].submitted_audio);
      } else {
        setSubmittedAudioUrl(null);
      }
  
      // 重置未提交的录音URL
      setAudioUrl(null);
      setUnsavedRecording(false);
  
      // 如果是最后一个单词，更新数据库
      if (currentIndex === words.length - 1 && !seenToday.has(currentWord)) {
        updateReviewCount(currentWord);
        setSeenToday(new Set([...seenToday, currentWord]));
      }
  
      console.log('words[currentIndex]:', words[currentIndex]);
  
      // 如果 `audio_files` 是对象，转换为数组形式
      const currentWordAudioFiles = words[currentIndex]?.audio_files
        ? Object.entries(words[currentIndex].audio_files).map(([filename, label]) => ({
            filename,
            label,
          }))
        : [];
      setAudioFiles(currentWordAudioFiles);
  
      setTimer(0);
      setShowExplanation(false);
      setShowChoices(false);
  
      console.log('displayedReviewCount:', displayedReviewCount);
      if (displayedReviewCount >= 1 && displayedReviewCount <= 3) {
        console.log('到这里了');
        setShowExplanation(true);
        playAudio(`${SHORT_URL}/audio/${currentWordAudioFiles[0]?.filename}?t=${new Date().getTime()}`);
      } else if (displayedReviewCount >= 4 && displayedReviewCount <= 6) {
        setTimeout(() => setShowExplanation(true), 2000);
        playAudio(`${SHORT_URL}/audio/${currentWordAudioFiles[0]?.filename}?t=${new Date().getTime()}`);
      } else if (displayedReviewCount >= 7) {
        playAudio(`${SHORT_URL}/audio/${currentWordAudioFiles[0]?.filename}?t=${new Date().getTime()}`);
        fetchIncorrectOptions();
        setTimer(2);
      }
    }
  }, [currentIndex, words]);
  

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => setTimer(t => t - 1), 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      if (displayedReviewCount >= 7) {
        setShowChoices(true); // Show choices for 7th review and beyond
        generateChoices();
      } else {
        setShowExplanation(true);
      }
    }
  }, [timer]);

  const getReviewCount = (currentIndex) => {
    const reviewCount = words[currentIndex].review_count;
    if (seenToday.has(words[currentIndex])) {
      setDisplayedReviewCount(reviewCount);  // 不增加次数
    } else {
      setDisplayedReviewCount(reviewCount + 1);  // UI显示增加1
    }
  };

  const updateReviewCount = async (baseForm) => {
    try {
      const utcOffset = getUTCOffset(); // 获取用户的 UTC 偏移
      await axios.post(`${BACKEND_URL}/update_word_review_count/`, {
        user_id: userId,
        base_form: baseForm,
        offset: getUTCOffset() // 添加 offset 到请求体
      });
    } catch (error) {
      console.error("Error updating word review count!", error);
    }
  };

  const playAudio = (audioSrc) => {
    const cachedAudio = audioCache[audioSrc];
    if (cachedAudio) {
      cachedAudio.currentTime = 0; // 确保从头播放
      cachedAudio.play();
    } else {
      const audio = new Audio(audioSrc);
      audio.play();
    }
  };

  const generateChoices = () => {
    const correctAnswer = words[currentIndex]?.meanings;
    console.log('incorrectOptionsCache:', incorrectOptionsCache);
    const incorrectOptions = incorrectOptionsCache?.[words[currentIndex]?.word] || [];
    console.log('incorrectOptionsCache?.[words[currentIndex]?.word]:', incorrectOptionsCache?.[words[currentIndex]?.word])
    const allOptions = [correctAnswer, ...incorrectOptions.slice(0, 3)];
    setChoices(shuffleArray(allOptions)); // Shuffle options for display
    setCorrectChoice(correctAnswer);
  };

  const handleChoiceSelect = (choice) => {
    if (choice === correctChoice) {
      alert("选择正确！");
      setTimeout(nextWord, 1000);
    } else {
      alert("选项错误，请重新选择！");
    }
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  // 切换单词时提示未提交录音
  const handleSwitchWord = (newIndex) => {
    console.log('currentWord:', words[newIndex]?.word);
    if (unsavedRecording) {
      const confirmSwitch = window.confirm("录音尚未提交，切换单词该录音将被清空，是否确认离开？");
      if (!confirmSwitch) {
        return; // 不切换单词
      } else {
        // 清空未提交的录音
        setAudioUrl(null);
        setUnsavedRecording(false);
      }
    }
    setCurrentIndex(newIndex);
  };

  const nextWord = () => {
    const currentWord = words[currentIndex]?.word;

    if (currentIndex < words.length - 1) {
      if (!seenToday.has(currentWord)) {
        updateReviewCount(currentWord);
        setSeenToday(new Set([...seenToday, currentWord]));
        setRemainingWordsCount(remainingWordsCount - 1);
      }
      handleSwitchWord(currentIndex + 1);
    }
  };

  const prevWord = () => {
    if (currentIndex > 0) {
      handleSwitchWord(currentIndex - 1);
    }
  };

// 录音相关功能
const startRecording = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // 检查支持的 MIME 类型
    const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
      ? "audio/mp4"
      : MediaRecorder.isTypeSupported("audio/aac")
      ? "audio/aac"
      : "audio/webm";

    mediaRecorder = new MediaRecorder(stream, { mimeType }); // 设置支持的格式
    audioChunks = [];

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.start();
    console.log("开始录音，使用格式:", mimeType);
  } catch (err) {
    console.error("无法启动录音:", err);
    alert("无法启动录音，请检查浏览器支持和麦克风设置。");
  }
};

const stopRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stop();
    mediaRecorder.onstop = () => {
      const mimeType = mediaRecorder.mimeType; // 获取实际使用的格式
      const audioBlob = new Blob(audioChunks, { type: mimeType }); // 使用实际格式创建 Blob
      setAudioBlob(audioBlob);
      setAudioUrl(URL.createObjectURL(audioBlob));
      setUnsavedRecording(true); // 标记未提交的录音
      console.log("录音完成，格式:", mimeType);
    };
  }
};

const handleUploadAudio = async () => {
  if (!audioBlob) return;

  // 确定扩展名
  const extension = audioBlob.type.includes("mp4")
    ? "mp4"
    : audioBlob.type.includes("aac")
    ? "aac"
    : "webm";
  const formData = new FormData();
  formData.append("file", audioBlob, `${words[currentIndex].word}_${userId}_1.${extension}`); // 使用动态扩展名

  try {
    const response = await axios.post(
      `${BACKEND_URL}/upload_card_audio/${userId}/${words[currentIndex].word}`,
      formData
    );
    const audioUrl = `${SHORT_URL}/audio/${response.data.filename}`;
    setSubmittedAudioUrl(audioUrl);
    words[currentIndex].submitted_audio = audioUrl;
    setAudioUrl(null); // 提交后清空录音区域的音频
    setUnsavedRecording(false); // 标记录音已提交
    alert("录音已上传");
  } catch (error) {
    console.error("录音上传失败", error);
  }
};

  const handleDeleteAudio = async () => {
    const confirmDelete = window.confirm('是否确认删除该音频？');
    if (confirmDelete) {
      try {
        await axios.delete(`${BACKEND_URL}/delete_card_audio/${userId}/${words[currentIndex].word}`);
        setSubmittedAudioUrl(null);
        words[currentIndex].submitted_audio = null;
        alert('录音已删除');
      } catch (error) {
        console.error('删除录音失败', error);
      }
    }
  };

  if (words.length === 0) {
    return <div>今天单词已背完，明天进入下一轮复习。</div>;
  }

  const { word, pronunciation, audio_files, meanings } = words[currentIndex] || {};
  const wordId = `${userId}_${word}`;
  const qTimestampId = `q, ${userId}, ${word}`;
  const audioLabels = ['读音', '发音拆分', '备注'];

  return (
    <div className="study-words">
      <h1>背单词</h1>
      <div className="word-card" style={{ position: 'relative' }}>
        <div className="remaining-words" style={{ position: 'absolute', top: '10px', left: '10px', background: 'rgba(255,255,255,0.8)', padding: '5px', borderRadius: '5px' }}>
          今天剩余 {remainingWordsCount} 个单词
        </div>

        <div style={{ marginBottom: '20px' }}>
          <label>选择预加载的音频数量:</label>
          <select value={preloadCount} onChange={(e) => setPreloadCount(Number(e.target.value))}>
            <option value={1}>每单词1个音频</option>
            <option value={2}>每单词2个音频</option>
            <option value={3}>每单词3个音频</option>
            <option value={4}>每单词4个音频</option>
            <option value={5}>每单词5个音频</option>
          </select>
        </div>

        {currentIndex === words.length - 1 && (
          <div style={{ textAlign: 'center', marginBottom: '10px', color: 'red' }}>已是最后一个单词</div>
        )}

        <h2>{word}</h2>
        <p>音标: {pronunciation}</p>

        {displayedReviewCount >= 7 && showChoices ? (
          <div>
            <p>选择正确的解释：</p>
            {choices.map((choice, index) => (
              <button key={index} onClick={() => handleChoiceSelect(choice)}>
                {choice}
              </button>
            ))}
          </div>
        ) : (
          <p>{showExplanation && words[currentIndex]?.meanings}</p>
        )}

        {/* 显示从wordListPage获取的音频 */}
        <div>
          {audioFiles.length > 0 ? (
            audioFiles.map(({ filename, label }, index) => (
              <div key={filename} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>{label || '备注'}:</span>
                <audio controls>
                  <source src={`${SHORT_URL}/audio/${filename}?t=${new Date().getTime()}`} type="audio/mp3" />
                </audio>
              </div>
            ))
          ) : (
            <p>无音频文件</p>
          )}
        </div>

        <>
      <QuestionButton
            timestampId={wordId}
            questions={questions}
            isQuestionExpanded={isQuestionExpanded}
            isAskingQuestion={isAskingQuestion}
            handleQuestionToggle={handleQuestionToggle}
        />
        {isAskingQuestion[wordId] && (
        <QuestionForm
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionAudio={questionAudio}
            setQuestionAudio={setQuestionAudio}
            handleSubmitQuestion={handleSubmitQuestion}
            timestampId={wordId}
            startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
            stopQuestionRecording={stopQuestionRecording}
            remainingPoints={remainingPoints}
            estimatedPoints={estimatedPoints}
            handleTextChange={handleTextChange}
            renderAudio={renderAudio}
            pendingAudio={pendingAudio}
            setAssignTeacher={setAssignTeacher}
            assignTeacher={assignTeacher}
            needTeacher={needTeacher}
        />
        )}
        {isQuestionExpanded[wordId] && (
        <AskQuestions
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionAudio={questionAudio}
            setQuestionAudio={setQuestionAudio}
            handleSubmitQuestion={handleSubmitQuestion}
            timestampId={wordId}
            startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
            stopQuestionRecording={stopQuestionRecording}
            remainingPoints={remainingPoints}
            estimatedPoints={estimatedPoints}
            handleTextChange={handleTextChange}
            renderAudio={renderAudio}
            pendingAudio={pendingAudio}
            qTimestampId={'qtimestamp_id'}
            followupText={followupText}
            handleSubmitFollowup={handleSubmitFollowup}
            setFollowupText={setFollowupText}
            questions={questions}
            needTeacher={needTeacher}
        />
        )}    
      </>
        <div className="review-count" style={{ position: 'absolute', top: '10px', right: '10px', background: 'rgba(255,255,255,0.8)', padding: '5px', borderRadius: '5px' }}>
          第{displayedReviewCount}次背
        </div>

        {/* 录音功能 */}
        <div>
          <button
            id="recordButton"
            onMouseDown={() => {
              document.getElementById("recordButton").innerText = "录音中...";
              startRecording();
            }}
            onMouseUp={() => {
              document.getElementById("recordButton").innerText = "按住录音";
              stopRecording();
            }}
          >
            按住录音
          </button>

          {/* 显示录音后的播放和提交按钮 */}
          {audioUrl && (
            <div>
              <audio controls src={audioUrl}></audio>
              <button onClick={handleUploadAudio}>提交</button>
            </div>
          )}

          {/* 显示已提交的音频和删除按钮 */}
          {submittedAudioUrl && (
            <div>
              <audio controls src={submittedAudioUrl}></audio>
              <button onClick={handleDeleteAudio}>删除</button>
            </div>
          )}
        </div>
      </div>

      <div className="navigation-buttons">
        <button onClick={prevWord} disabled={currentIndex === 0}>上一个单词</button>
        <button onClick={nextWord}>下一个单词</button>
      </div>
    </div>
  );
}

export default StudyWords;
