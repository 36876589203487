import React, { useState, useEffect } from 'react';
import axios from 'axios';

function InvitationPage({ userId }) {
  const [invitationCode, setInvitationCode] = useState('');
  const [invitedUserCount, setInvitedUserCount] = useState(0);
  const [paidUserCount, setPaidUserCount] = useState(0);
  const [invitedUserIds, setInvitedUserIds] = useState([]);
  const [inputCode, setInputCode] = useState('');
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  // 加载邀请码和统计数据
  useEffect(() => {
    axios.get(`/users/${userId}/invitation`)
      .then(response => {
        const { invitation_code, invited_user_count, paid_user_count, invited_users } = response.data;
        setInvitationCode(invitation_code);
        setInvitedUserCount(invited_user_count);
        setPaidUserCount(paid_user_count);
        setInvitedUserIds(invited_users);
      })
      .catch(error => {
        console.error("Error fetching invitation data:", error);
      });
  }, [userId]);

  // 保存邀请码
  const handleSaveCode = () => {
    if (invitationCode) {
      setError("邀请码已设置，不能更改。");
      return;
    }

    if (!inputCode) {
      setError("请输入邀请码。");
      return;
    }

    // 显示确认对话框
    setShowConfirmation(true);
  };

  // 确认设置邀请码
  const confirmSaveCode = () => {
    axios.post(`/users/${userId}/invitation`, { invitation_code: inputCode })
      .then(() => {
        setInvitationCode(inputCode);
        setError("");
        alert("邀请码已保存！");
      })
      .catch(error => {
        console.error("Error saving invitation code:", error);
        setError("邀请码保存失败，可能已被占用。");
      });
    setShowConfirmation(false);
  };

  return (
    <div>
      <h2>邀请码</h2>
      {invitationCode ? (
        <p>你的邀请码是：<strong>{invitationCode}</strong></p>
      ) : (
        <div>
          <p>邀请码用于邀请其他人注册，只可设置一次，设置后不可更改。</p>
          <input
            type="text"
            placeholder="输入邀请码"
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
          />
          <button onClick={handleSaveCode}>保存邀请码</button>
        </div>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      {/* 确认对话框 */}
      {showConfirmation && (
        <div style={{ background: '#f9f9f9', padding: '1em', border: '1px solid #ccc', marginTop: '1em' }}>
          <p>设置的邀请码为<strong> {inputCode} </strong>，确定后不可更新，是否确定？</p>
          <button onClick={confirmSaveCode}>确定</button>
          <button onClick={() => setShowConfirmation(false)}>取消</button>
        </div>
      )}
      
      <p>已邀请用户数：{invitedUserCount}</p>
      <p>已付费用户数：{paidUserCount}</p>
      <p>已邀请用户ID列表：</p>
      <ul>
        {invitedUserIds.map(id => (
          <li key={id}>{id}</li>
        ))}
      </ul>
    </div>
  );
}

export default InvitationPage;
