import React from 'react';
import axios from 'axios';

function SynSelections({ filename }) {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/api';

  const confirmAndClearSelections = async (endpoint, message) => {
    const confirmed = window.confirm(
      `${message}\n操作不可恢复，是否确认清空？`
    );

    if (!confirmed) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${BACKEND_URL}/${endpoint}/${filename}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(`${message} 已清空成功！`);
    } catch (error) {
      console.error(`Error clearing selections: ${message}`, error);
      alert(`${message} 清空失败`);
    }
  };

  const clearWordListSelections = () => {
    confirmAndClearSelections('clear_word_list', '清空单词列表选项');
  };

  const clearDetailedSelections = () => {
    confirmAndClearSelections('clear_detailed_words', '清空详解页单词选项');
  };

  const clearAllWordsSelections = () => {
    confirmAndClearSelections('clear_all_words', '清空全部单词选项');
  };

  // Function to sync "单词列表选项"
  const syncWordListSelections = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${BACKEND_URL}/sync_word_list/${filename}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { synced_words } = response.data;

      if (synced_words > 0) {
        alert(`单词列表选项同步成功！同步了 ${synced_words} 个新单词。`);
      } else {
        alert('没有新单词需要同步。');
      }
    } catch (error) {
      console.error('Error syncing word list selections:', error);
      alert('同步单词列表选项失败');
    }
  };

  // Function to sync "详解页单词选项"
  const syncDetailedSelections = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${BACKEND_URL}/sync_detailed_words/${filename}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(`详解页单词选项同步成功！同步了 ${response.data.synced_words} 个单词。`);
    } catch (error) {
      console.error('Error syncing detailed selections:', error);
      alert('同步详解页单词选项失败');
    }
  };

  // Function to sync "全部单词选项"
  const syncAllWordsSelections = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${BACKEND_URL}/sync_all_words/${filename}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { synced_words } = response.data;

      if (synced_words > 0) {
        alert(`全部单词选项同步成功！同步了 ${synced_words} 个新单词。`);
      } else {
        alert('没有新单词需要同步。');
      }
    } catch (error) {
      console.error('Error syncing all words selections:', error);
      alert('同步全部单词选项失败');
    }
  };
  {console.log('filename:', filename)}
  return (
    <div>
      <h1>同步与清空选项</h1>
      <button onClick={syncWordListSelections}>同步单词列表选项</button>
      <button onClick={syncDetailedSelections}>同步详解页单词选项</button>
      <button onClick={syncAllWordsSelections}>同步全部单词选项</button>
      <button onClick={clearWordListSelections}>清空单词列表选项</button>
      <button onClick={clearDetailedSelections}>清空详解页单词选项</button>
      <button onClick={clearAllWordsSelections}>清空全部单词选项</button>
    </div>
  );
}

export default SynSelections;
