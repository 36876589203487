import React, { useState } from 'react';
import './Modal.css';
import axios from 'axios';

function Modal({ setIsModalOpen, setIsLoggedIn }) {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isWeakPassword, setIsWeakPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const [invitationCode, setInvitationCode] = useState(""); // 邀请码输入框
  const [invitationError, setInvitationError] = useState(""); // 邀请码错误提示
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [countdown, setCountdown] = useState(0); // 60秒倒计时
  const [resetPasswordMode, setResetPasswordMode] = useState(false); // 控制重置密码的状态
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const toggleMode = () => {
    setIsLoginMode(!isLoginMode);
    setFormError("");
    setInvitationError(""); // 清空邀请码错误提示
    setForgotPasswordMode(false); // 切换模式时关闭找回密码
    setResetPasswordMode(false); // 切换模式时关闭重置密码
    clearPasswordFields(); // 清空密码输入框
  };

  const clearPasswordFields = () => {
    setPassword("");
    setConfirmPassword("");
    setPasswordError(""); // 清空错误提示
  };

  const handleInvitationCodeChange = async (code) => {
    setInvitationCode(code);
    setInvitationError(""); // 清空错误提示

    if (code) {
      try {
        const response = await axios.get(`${BACKEND_URL}/check-invitation-code/${code}`);
        if (!response.data.exists) {
          setInvitationError("该邀请码不存在，如果没有邀请码可以不填写");
        }
      } catch (error) {
        console.error("Error checking invitation code:", error);
      }
    }
  };
  
  const checkPasswordStrength = (password) => {
    const strongPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; // 至少8位，包含字母和数字
    return strongPassword.test(password);
  };

  const handleEmailOrPhoneChange = (value) => {
    setEmailOrPhone(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmail(emailRegex.test(value));
  };

  const sendVerificationCode = async () => {
    if (!isEmail) {
      alert("请输入有效的邮箱"); // 检查邮箱格式
      return;
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/send-verification-code/`, {
        email: emailOrPhone
      });
      setVerificationCode(response.data.code);
      alert("验证码已发送，请查收！");
      startCountdown(); // 开始倒计时
    } catch (error) {
      console.error("验证码发送失败", error);
    }
  };

  const startCountdown = () => {
    setCountdown(60); // 设置倒计时为60秒
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!emailOrPhone || !password || (!isLoginMode && !confirmPassword)) {
      setFormError("请先完成上面填写");
      return;
    }
  
    if (!isLoginMode && password !== confirmPassword) {
      setPasswordError("两次输入的密码不一致");
      return;
    }
  
    if (!isLoginMode && isWeakPassword) {
      setPasswordError("密码太弱，必须包含至少8位字符，且包含字母和数字");
      return;
    }
  
    const url = isLoginMode ? "/login/" : "/register/";
    try {
      const response = await axios.post(`${BACKEND_URL}${url}`, {
        email: emailOrPhone,
        password: password,
        verification_code: inputCode,
        invitation_code: invitationCode || null,
        avatar: null,
      });
      
      if (isLoginMode) {
        const { user_id, role, access_token, refresh_token, nickname, avatar } = response.data;
  
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userRole', role);
        localStorage.setItem('userId', user_id);
        localStorage.setItem('token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
  
        // 将昵称和头像传递给 App.js
        setIsLoggedIn(true, role, avatar, nickname);
      }
      setIsModalOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setFormError("账号或密码错误");
      } else if (error.response && error.response.status === 429) {
        setFormError("登录尝试太频繁，请隔一分钟再尝试");
      } else {
        console.error("There was an error!", error);
      }
    }
  };
  

  const handleForgotPassword = () => {
    setForgotPasswordMode(true);
    setFormError("");
    setInputCode(""); // 清空验证码输入框
    setResetPasswordMode(false); // 重置状态
  };

  const confirmPasswordRecovery = async () => {
    if (!isEmail) {
      alert("请输入有效的邮箱"); // 检查邮箱格式
      return;
    }
    await sendVerificationCode();
    startCountdown();
  };

  const handleCodeConfirmation = async () => {
    if (inputCode !== verificationCode) {
      alert("验证码错误，请重试");
      return;
    }
    // 进入重置密码模式
    setResetPasswordMode(true);
    setForgotPasswordMode(false); // 隐藏找回密码内容
    clearPasswordFields(); // 清空密码输入框以防重复输入
  };

  const handlePasswordReset = async () => {
    // 检查新密码
    if (password !== confirmPassword) {
        setPasswordError("两次输入的密码不一致");
        return;
    }
    if (isWeakPassword) {
        setPasswordError("密码太弱，必须包含至少8位字符，且包含字母和数字");
        return;
    }

    // 发送重置密码请求到后端
    try {
        const response = await axios.post(`${BACKEND_URL}/reset-password/`, {
            email: emailOrPhone,
            new_password: password,
        });
        alert("密码重置成功！");
        setIsModalOpen(false);
    } catch (error) {
        console.error("重置密码失败", error);
        alert("重置密码失败，请重试。");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={() => setIsModalOpen(false)}>×</button>
        {forgotPasswordMode && !resetPasswordMode ? ( // 如果在找回密码模式，只显示相关内容
          <div>
            <h3>找回密码</h3>
            <input
              type="text"
              placeholder="请输入注册邮箱"
              value={emailOrPhone}
              onChange={(e) => handleEmailOrPhoneChange(e.target.value)}
            />
            <button onClick={confirmPasswordRecovery} disabled={countdown > 0}>
              {countdown > 0 ? `${countdown}秒后再试` : "发送邮箱验证码"}
            </button>
            <input
              type="text"
              placeholder="请输入验证码"
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
            />
            <button onClick={handleCodeConfirmation}>确认</button>
          </div>
        ) : resetPasswordMode ? ( // 如果在重置密码模式，只显示相关内容
          <div>
            <h3>重置密码</h3>
            <input
              type="password"
              placeholder="新密码"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setIsWeakPassword(!checkPasswordStrength(e.target.value));
              }}
            />
            {isWeakPassword && <p className="error-text">必须包含至少8位字符，且包含字母和数字</p>}
            <input
              type="password"
              placeholder="确认新密码"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button onClick={handlePasswordReset}>确认修改</button>
            {passwordError && <p className="error-text">{passwordError}</p>}
          </div>
        ) : ( // 其他模式的内容
          <>
            <h2>{isLoginMode ? "登录" : "注册"}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>邮箱或手机号</label>
                <input
                  type="text"
                  value={emailOrPhone}
                  onChange={(e) => handleEmailOrPhoneChange(e.target.value)}
                  required
                />
              </div>
              {!isLoginMode &&
              <div>
                <div className="form-group">
                  <label>邀请码（可选）</label>
                  <input
                    type="text"
                    value={invitationCode}
                    onChange={(e) => handleInvitationCodeChange(e.target.value)}
                  />
                  {invitationError && <p className="error-text">{invitationError}</p>}
                </div>
              </div>
              }

              <div className="form-group">
                <label>密码</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setIsWeakPassword(!checkPasswordStrength(e.target.value));
                  }}
                  required
                />
                {isWeakPassword && <p className="error-text">至少8位，包含字母和数字</p>}
              </div>
              {!isLoginMode && (
                <>
                  <div className="form-group">
                    <label>确认密码</label>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    {passwordError && <p className="error-text">{passwordError}</p>}
                  </div>
                  {isEmail && (
                    <div className="form-group">
                      <label>验证码</label>
                      <input
                        type="text"
                        value={inputCode}
                        onChange={(e) => setInputCode(e.target.value)}
                        required
                      />
                      <button type="button" onClick={sendVerificationCode} disabled={countdown > 0}>
                        {countdown > 0 ? `${countdown}秒后再试` : "发送验证码"}
                      </button>
                    </div>
                  )}
                </>
              )}
              <button type="submit">{isLoginMode ? "登录" : "注册"}</button>
              {formError && <p className="error-text">{formError}</p>} {/* 显示表单错误提示 */}
            </form>
            <p>
              {isLoginMode ? "未注册？" : "已注册？"}
              <span onClick={toggleMode}>{isLoginMode ? "点此注册" : "点此登录"}</span>
            </p>
            {isLoginMode && (
              <p onClick={handleForgotPassword} style={{ color: 'blue', cursor: 'pointer' }}>
                忘记密码？
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Modal;