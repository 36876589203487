import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UserProfilePage({ userId, setUserAvatar, setUserNickname }) {
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [birthDate, setBirthDate] = useState({ year: '', month: '', day: '' });
  const [avatar, setAvatar] = useState(''); // 存储头像 URL
  const [error, setError] = useState('');
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const SHORT_URL = BACKEND_URL.split("/api")[0];
  // 加载用户数据
  useEffect(() => {
    axios.get(`${BACKEND_URL}/users/${userId}`)
      .then(response => {
        const { email, nickname, birth_date, avatar } = response.data;
        setEmail(email);
        setNickname(nickname || '');
        setAvatar(avatar || 'default.png'); // 设置默认头像
        if (birth_date) {
          const date = new Date(birth_date);
          setBirthDate({
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
          });
        }
      })
      .catch(error => {
        console.error("Error fetching user data:", error);
      });
  }, [userId]);

  // 处理头像上传
  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("avatar", file);

    try {
      const response = await axios.post(`${BACKEND_URL}/users/${userId}/upload-avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const newAvatar = response.data.avatar;
      setAvatar(newAvatar); // 更新 UserProfilePage 的头像显示
      setUserAvatar(newAvatar); // 更新 App.js 的全局头像显示
      localStorage.setItem("userAvatar", newAvatar); // 更新本地存储
      alert("头像已上传并更新！");
    } catch (error) {
      console.error("Error uploading avatar:", error);
    }
  };

  // 处理表单提交
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const birth_date = birthDate.year && birthDate.month && birthDate.day
      ? `${birthDate.year}-${birthDate.month}-${birthDate.day}`
      : null;
  
    const requestData = {};
    if (nickname) requestData.nickname = nickname;
    if (birth_date) requestData.birth_date = birth_date;
  
    axios.get(`${BACKEND_URL}/check-nickname/${nickname}?user_id=${userId}`)
    .then(response => {
      if (response.data.isUnique || !nickname) {
        axios.put(`${BACKEND_URL}/users/${userId}`, requestData)
          .then(() => {
            alert("资料已保存！");
            setUserNickname(nickname);
          })
          .catch(error => {
            console.error("Error saving user data:", error);
          });
      } else {
        setError('该昵称已存在，请换一个。');
      }
    })
    .catch(error => {
      console.error("Error checking nickname:", error);
    });
  
  };
  
  // 更新生日字段
  const handleBirthDateChange = (e) => {
    const { name, value } = e.target;
    setBirthDate((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div>
      <h2>个人资料</h2>
      <div>
        <img
          src={`${SHORT_URL}/Userpics/${avatar}`} // 动态加载头像
          alt="User Avatar"
          style={{ width: '100px', height: '100px', borderRadius: '50%' }} // 设置圆形头像样式
        />
        <div>
          <label htmlFor="avatar-upload" style={{ cursor: 'pointer', color: 'blue' }}>更换头像</label>
          <input
            type="file"
            id="avatar-upload"
            style={{ display: 'none' }}
            onChange={handleAvatarChange}
            accept="image/*"
          />
        </div>
        <small>提示：头像最好为圆形或正方形。</small>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>邮箱：</label>
          <span>{email}</span>
        </div>
        <div>
          <label>昵称：</label>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            required
          />
          {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
        <div>
          <label>出生日期：</label>
          <select name="year" value={birthDate.year} onChange={handleBirthDateChange}>
            <option value="">年</option>
            {[...Array(100).keys()].map(i => (
              <option key={i} value={2023 - i}>{2023 - i}</option>
            ))}
          </select>
          <select name="month" value={birthDate.month} onChange={handleBirthDateChange}>
            <option value="">月</option>
            {[...Array(12).keys()].map(i => (
              <option key={i} value={i + 1}>{i + 1}</option>
            ))}
          </select>
          <select name="day" value={birthDate.day} onChange={handleBirthDateChange}>
            <option value="">日</option>
            {[...Array(31).keys()].map(i => (
              <option key={i} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        </div>

        <button type="submit">保存</button>
      </form>
    </div>
  );
}

export default UserProfilePage;
