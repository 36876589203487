import React, { useState, useEffect } from 'react';
import axios from 'axios';

function WordSelection() {
    const [files, setFiles] = useState([]);
    const [selectedWords, setSelectedWords] = useState(null);
    const [wordOptions, setWordOptions] = useState({});
    const [incorrectOptions, setIncorrectOptions] = useState([]);
    const [savedIncorrectOptions, setSavedIncorrectOptions] = useState([]);
    const [displayOptions, setDisplayOptions] = useState({});
    const [selectedFileId, setSelectedFileId] = useState(null); // 新增状态
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
    useEffect(() => {
        // Fetch all rows from `uploaded_files`
        axios.get(`${BACKEND_URL}/files`)
            .then(response => setFiles(response.data))
            .catch(error => console.error("Error fetching files:", error));
        
        // Fetch all `word_options`
        axios.get(`${BACKEND_URL}/word_options`)
            .then(response => {
                const options = {};
                response.data.forEach(item => {
                    options[item.word] = item.incorrect_choices;
                });
                setWordOptions(options);
            })
            .catch(error => console.error("Error fetching word options:", error));

        // Fetch all `incorrect_options`
        axios.get(`${BACKEND_URL}/incorrect_options`)
            .then(response => setIncorrectOptions(response.data))
            .catch(error => console.error("Error fetching incorrect options:", error));
    }, []);

    const handleFileClick = (fileId) => {
        setSelectedFileId(fileId); // 设置当前文件 ID
    
        // 获取选中文件的 incorrect_options
        axios.get(`${BACKEND_URL}/saved_incorrect_options/${fileId}`)
            .then(response => {
                setSavedIncorrectOptions(response.data);
            })
            .catch(error => console.error("Error fetching incorrect options:", error));
    
        // 获取选中文件的 base_forms
        axios.get(`${BACKEND_URL}/files/${fileId}/base_forms`)
            .then(response => {
                const words = response.data.base_forms;
                setSelectedWords(words);  // 设置所选单词列表
            })
            .catch(error => console.error("Error fetching base forms:", error));
    };
    
    // 使用 useEffect 监听 savedIncorrectOptions 和 selectedWords
    useEffect(() => {
        if (!selectedWords || !savedIncorrectOptions) return;
    
        const initialOptions = {};
        selectedWords.forEach(word => {
            // 检查 savedIncorrectOptions 和 wordOptions 中是否已存在选项
            if (savedIncorrectOptions[word]) {
                initialOptions[word] = savedIncorrectOptions[word];
                updateDatabaseOptions(selectedFileId, word, initialOptions[word]);
            } else if (wordOptions[word]) {
                initialOptions[word] = wordOptions[word];
                updateDatabaseOptions(selectedFileId, word, initialOptions[word]);
            } else {
                // 随机选择 3 个 incorrectOptions 作为新的选项
                initialOptions[word] = getRandomOptions(incorrectOptions, 3);
                updateDatabaseOptions(selectedFileId, word, initialOptions[word]);
            }
        });
    
        setDisplayOptions(initialOptions);
    }, [savedIncorrectOptions, selectedWords, selectedFileId]);
    
    
    

    const getRandomOptions = (options, count) => {
        const shuffled = [...options].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    let debounceTimeout;

    const updateDatabaseOptions = (fileId, word, options) => {
        clearTimeout(debounceTimeout); // 清除上一个请求
        debounceTimeout = setTimeout(() => {
            // 更新 `word_options`
            axios.post(`${BACKEND_URL}/word_options`, {
                word: word,
                incorrect_choices: options
            }).catch(error => console.error("Error updating word options:", error));
    
            // 更新 `uploaded_files`
            axios.post(`${BACKEND_URL}/files/${fileId}/update_incorrect_options`, {
                word: word,
                incorrect_choices: options
            }).catch(error => console.error("Error updating uploaded file options:", error));
        }, 300); // 延迟 300 毫秒
    };
    console.log('savedIncorrectOptions:', savedIncorrectOptions);
    const handleRefreshOptions = (word) => {
        if (!selectedFileId) return;  // 检查是否有选中的文件
        const newOptions = getRandomOptions(incorrectOptions, 3);
        setDisplayOptions(prev => ({ ...prev, [word]: newOptions }));
        updateDatabaseOptions(selectedFileId, word, newOptions);  // 使用 selectedFileId
    };

    return (
        <div>
            <h2>Uploaded Files</h2>
            <div>
                {files.map(file => (
                    <button 
                        key={file.id} 
                        onClick={() => handleFileClick(file.id)}
                        style={{ display: 'block', marginBottom: '10px' }}
                    >
                        {file.filename}
                    </button>
                ))}
            </div>
            {selectedWords && (
                <div>
                    <h3>Word List</h3>
                    <ul>
                        {selectedWords.map((word, index) => (
                            <li key={index}>
                                {word}
                                {displayOptions[word] && Array.isArray(displayOptions[word]) && (
                                    <span style={{ marginLeft: '10px' }}>
                                        {displayOptions[word].map((option, i) => (
                                            <span key={i} style={{ marginLeft: '5px' }}>{option}</span>
                                        ))}
                                        <button 
                                            style={{ marginLeft: '10px' }} 
                                            onClick={() => handleRefreshOptions(word)}
                                        >
                                            刷新选项
                                        </button>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default WordSelection;
