import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WordListPage from './WordListPage';
import AllWords from './AllWords';
import SynSelections from './SynSelections';
import DetailedPage from './DetailedPage';
import './CourseEditor.css';

function CourseEditor({ setCurrentPage, setAllWords, setWordListPage, setSynSelections }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [currentView, setCurrentView] = useState('courseEditor');  // 控制当前显示的页面
  const [currentFilename, setCurrentFilename] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  useEffect(() => {
    fetchFiles();
    fetchUserRole();
  }, []);

  const fetchFiles = async () => {
    try {
        const response = await axios.get(`${BACKEND_URL}/files`);
        const sortedFiles = response.data.sort((a, b) => a.order - b.order);  // 按 order 排序
        setFiles(sortedFiles);
    } catch (error) {
        console.error("There was an error fetching the files!", error);
    }
  };

  const fetchUserRole = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token not found!");
      return;
    }
  
    try {
      const response = await axios.get(`${BACKEND_URL}/users/me/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUserRole(response.data.role);  // 将用户角色存储起来
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await axios.post(`${BACKEND_URL}/upload/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setFiles([...files, response.data]);
        setSelectedFile(null);
      } catch (error) {
        console.error("There was an error uploading the file!", error);
      }
    } else {
      alert("请先选择一个文件");
    }
  };
  const handleSrtPublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/srt_publish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, srt_published: true } : file
      ));
    } catch (error) {
      console.error("Error publishing srt!", error);
    }
  };
  
  const handleSrtUnpublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/srt_unpublish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, srt_published: false } : file
      ));
    } catch (error) {
      console.error("Error unpublishing srt!", error);
    }
  };

  const handleWordOverviewPublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/word_overview_publish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, word_overview_published: true } : file
      ));
    } catch (error) {
      console.error("Error publishing file!", error);
    }
  };
  
  const handleWordOverviewUnpublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/word_overview_unpublish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, word_overview_published: false } : file
      ));
    } catch (error) {
      console.error("Error unpublishing file!", error);
    }
  };

  const handleStudentPagePublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/student_page_publish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, student_page_published: true } : file
      ));
    } catch (error) {
      console.error("Error publishing file!", error);
    }
  };
  
  const handleStudentPageUnpublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/student_page_unpublish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, student_page_published: false } : file
      ));
    } catch (error) {
      console.error("Error unpublishing file!", error);
    }
  };

  const handleStudyWordPublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/study_word_publish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, study_word_published: true } : file
      ));
    } catch (error) {
      console.error("Error publishing study word!", error);
    }
  };

  const handleStudyWordUnpublish = async (filename) => {
    try {
      await axios.post(`${BACKEND_URL}/study_word_unpublish/${filename}`);
      setFiles(files.map(file => 
        file.filename === filename ? { ...file, study_word_published: false } : file
      ));
    } catch (error) {
      console.error("Error unpublishing study word!", error);
    }
  };
  
  const handleViewWordList = (filename) => {
    setCurrentFilename(filename);  // 设置当前要显示的文件名
    setCurrentView('wordList');  // 切换到“单词本”页面
  };

  const handleViewAllWordList = (filename) => {
    setCurrentFilename(filename);  // 设置当前要显示的文件名
    setCurrentView('allWords');  // 切换到“单词本”页面
  };
  const handleViewDetailedPage = (filename) => {
    setCurrentFilename(filename);  // 设置当前要显示的文件名
    setCurrentView('detailedPage');  // 切换到“详解”页面
  };

  const handleSynSelections = (filename) => {
    setCurrentFilename(filename);  // 设置当前要显示的文件名
    setCurrentView('synSelections');  // 切换到“单词本”页面
  };

  const handleFileDelete = async (filename) => {
    try {
      await axios.delete(`${BACKEND_URL}/files/${filename}`);
      setFiles(files.filter(file => file.filename !== filename));
    } catch (error) {
      console.error("There was an error deleting the file!", error);
    }
  };

  // 渲染不同页面的逻辑
  if (currentView === 'wordList') {
    return <WordListPage filename={currentFilename} />;
  } else if (currentView === 'allWords') {
    return <AllWords filename={currentFilename} />;
  } else if (currentView === 'detailedPage') {
    return <DetailedPage filename={currentFilename} />;
  } else if (currentView === 'synSelections') {
    return <SynSelections filename={currentFilename} />;
  }

  // 默认显示课程编辑器页面
  return (
    <div className="course-editor">
      <h1>编写课程</h1>
      <div className="upload-section">
        <input className="file-input" type="file" accept=".srt" onChange={handleFileChange} />
        <button className="btn" onClick={handleFileUpload}>上传文件</button>
      </div>

      <h3>已上传文件:</h3>
      <ul className="file-list">
        {files.map(file => (
          <div key={file.filename} className="file-item">
            <h2>{file.filename}</h2>
            <button onClick={() => handleViewWordList(file.filename)}>单词本</button><br />
            <button onClick={() => handleViewAllWordList(file.filename)}>所有单词</button><br />
            <button onClick={() => handleViewDetailedPage(file.filename)}>详解</button><br />
            <button onClick={() => handleSynSelections(file.filename)}>同步选择</button><br />
            <button className="btn-small" onClick={() => {
                if (file.srt_published) {
                    handleSrtUnpublish(file.filename); // 取消发布
                } else {
                    handleSrtPublish(file.filename); // 发布
                }
            }}>
                {file.srt_published ? '取消发布字幕文件' : '发布字幕文件'}
            </button><br />
            <button className="btn-small" onClick={() => {
                if (file.word_overview_published) {
                    handleWordOverviewUnpublish(file.filename); // 取消发布
                } else {
                    handleWordOverviewPublish(file.filename); // 发布
                }
            }}>
                {file.word_overview_published ? '取消发布单词一览' : '发布单词一览'}
            </button><br />
            <button className="btn-small" onClick={() => {
                if (file.study_word_published) {
                    handleStudyWordUnpublish(file.filename); // 取消发布
                } else {
                    handleStudyWordPublish(file.filename); // 发布
                }
            }}>
                {file.study_word_published ? '取消发布背单词' : '发布背单词'}
            </button><br />
            <button className="btn-small" onClick={() => {
                if (file.student_page_published) {
                    handleStudentPageUnpublish(file.filename); // 取消发布
                } else {
                    handleStudentPagePublish(file.filename); // 发布
                }
            }}>
                {file.student_page_published ? '取消发布学生详情页' : '发布学生详情页'}
            </button><br />

            <button className="btn-delete" onClick={() => handleFileDelete(file.filename)}>删除</button>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default CourseEditor;
