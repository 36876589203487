import React from 'react';

export const QuestionForm = ({
  questionText,
  setQuestionText,
  questionAudio,
  estimatedPoints,
  remainingPoints, // 新增接收剩余积分
  handleTextChange,
  setQuestionAudio,
  handleSubmitQuestion,
  timestampId,
  startQuestionRecording,
  stopQuestionRecording,
  renderAudio,
  pendingAudio,
  setAssignTeacher,
  assignTeacher,
  needTeacher,
}) => (
  <div>
    <textarea
      value={questionText}
      onChange={(e) => handleTextChange(e.target.value)}
      placeholder="请输入问题"
    />
    <button
      id={timestampId}
      onMouseDown={() => {
        document.getElementById(timestampId).innerText = "录音中...";
        startQuestionRecording(timestampId);
      }}
      onMouseUp={() => {
        document.getElementById(timestampId).innerText = "重新录音";
        stopQuestionRecording(timestampId);
      }}
    >
      {pendingAudio && pendingAudio.timestampId === timestampId ? '重新录音' : '录音'}
    </button>
    {renderAudio(timestampId)}
    <button
      onClick={() => handleSubmitQuestion(timestampId)}
      disabled={estimatedPoints > remainingPoints} // 禁用按钮
    >
      {estimatedPoints > remainingPoints ? "积分不足，无法提交" : "提交问题"}
    </button>
    <p>剩余积分: {remainingPoints}</p>
    <p>预计积分消耗: {estimatedPoints}</p>
    {needTeacher && 
        <button onClick={() => setAssignTeacher(!assignTeacher)}>
          {assignTeacher ? '指定老师' : '自动分配老师'}
        </button>
    }
  </div>
);
