import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useQuestions } from './useQuestions';
import { QuestionButton } from './QuestionButton';
import { QuestionForm } from './QuestionForm';
import { AskQuestions } from './AskQuestions';
import './MyMessagesPage.css';

function MyMessagesPage({ fileName, messages, userId }) {
  const [allMessages, setAllMessages] = useState([]);  // 保存历史和实时消息
  const [expanded, setExpanded] = useState({});  // 控制每个 question_id 是否展开
  const [newMessageFlag, setNewMessageFlag] = useState({});  // 标记哪些 question_id 有新消息
  const [userRole, setUserRole] = useState('');  // 用于存储当前用户角色
  const [countdown, setCountdown] = useState({}); // 保存倒计时的剩余时间
  const removedQuestions = useRef([]);  // 使用 useRef 持久化移除的问题
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const AUDIO_URL = BACKEND_URL.split("/api")[0];
  // 输入框和录音相关的状态
  const [replyText, setReplyText] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const mediaRecorderRef = useRef(null);
  const [question, setQuestion] = useState(null);
  const [estimatedReplyPoints, setEstimatedReplyPoints] = useState(0);  // 新增: 预计消耗积分
  const needTeacher = true;
  let mediaRecorder = null;
  let audioChunks = [];
  const {
    questions,
    isAskingQuestion,
    isQuestionExpanded,
    questionText,
    setQuestionText,
    questionAudio,
    setQuestionAudio,
    handleQuestionToggle,
    handleSubmitQuestion,
    fetchQuestions,
    startQuestionRecording,
    stopQuestionRecording,
    remainingPoints,
    setRemainingPoints,
    estimatedPoints,
    handleTextChange,
    renderAudio,
    pendingAudio,
    followupText,
    handleSubmitFollowup,
    setFollowupText,
    setAssignTeacher,
    assignTeacher,
    setQuestions,
  } = useQuestions(fileName = null, userId, needTeacher);

  useEffect(() => {
    fetchQuestions();
    console.log('执行了fetchQuestions');
  }, [userId]);
  
  useEffect(() => {
    setQuestion(questions[0])
  }, [questions]);
  // 获取用户角色
  useEffect(() => {
    const role = localStorage.getItem('userRole');
    setUserRole(role);  // 获取登录的用户角色
  }, []);

  // 获取历史消息
  useEffect(() => {
    const fetchMessages = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${BACKEND_URL}/my_messages/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAllMessages(response.data);
        const messagesMap = response.data.reduce((acc, item) => {
          if (item.question_id) {
              acc[item.question_id] = item;
          }
          return acc;
        }, {});
        console.log('messagesMap:', messagesMap);
        setQuestions(messagesMap);
        console.log('Fetched historical messages:', response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    // 初始化获取剩余积分
    const fetchUserPoints = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/users/${userId}/points`);
        setRemainingPoints(response.data.remaining_points);
      } catch (error) {
        console.error("Error fetching user points:", error);
      }
    };
    fetchUserPoints();
  }, [userId]);

  useEffect(() => {
    // 当 replyText 或 audioBlob 改变时，重新计算预计消耗积分
    calculateReplyPoints();
  }, [replyText, audioBlob]);


  // 计算预计消耗积分的函数
  const calculateReplyPoints = () => {
    let points = 0;

    // 计算文字积分
    for (const char of replyText) {
      points += /[\u4e00-\u9fa5]/.test(char) ? 5 : 1; // 汉字5积分，英文字符1积分
    }

    // 如果有录音，计算音频积分
    if (audioBlob) {
      const audioDuration = audioBlob.size / (1024 * 10);  // 假设文件大小为10KB每秒
      points += Math.round(audioDuration) * 10; // 每秒录音10积分
    }

    setEstimatedReplyPoints(points);
  };

  useEffect(() => {
    if (messages.length > 0) {
      console.log('新消息传递到了MyMessagesPage:', messages);
      
      // 创建一个 Set，用于追踪唯一的消息
      const uniqueMessagesSet = new Set();
  
      // 过滤唯一的消息
      const uniqueMessages = messages.filter((message) => {
        const messageKey = `${message.text}-${message.last_assigned_at}`;
        
        // 检查是否已经存在于 Set 中，如果不存在则添加
        if (!uniqueMessagesSet.has(messageKey)) {
          uniqueMessagesSet.add(messageKey);
          return true; // 保留此消息
        }
        return false; // 忽略重复消息
      });
      console.log('uniqueMessages:', uniqueMessages);
      // 将唯一的消息更新到所有消息
      uniqueMessages.forEach((message) => updateAllMessages(message));
    }
  }, [messages]);
  

  // 点击按钮切换展开/折叠
  const toggleExpand = (question_id) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [question_id]: !prevExpanded[question_id],
    }));

    // 消息展开后，取消新消息标记
    setNewMessageFlag((prevNewMessageFlag) => ({
      ...prevNewMessageFlag,
      [question_id]: false,
    }));
  };

// 录音功能的处理
const startRecording = () => {
  audioChunks = []; // 清空音频块数组，以确保每次录音开始时都是新的数据

  navigator.mediaDevices.getUserMedia({ audio: true })
    .then((stream) => {
      // 检测支持的 MIME 类型
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : MediaRecorder.isTypeSupported("audio/aac")
        ? "audio/aac"
        : "audio/webm";

      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType }); // 设置支持的格式
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunks.push(event.data); // 收集音频块数据
      };
      mediaRecorderRef.current.start();
      console.log("开始录音，使用格式:", mimeType);
    })
    .catch((err) => console.error("Error accessing microphone", err));
};

const stopRecording = () => {
  if (mediaRecorderRef.current) {
    mediaRecorderRef.current.stop();
    mediaRecorderRef.current.onstop = () => {
      const mimeType = mediaRecorderRef.current.mimeType; // 获取实际 MIME 类型
      const extension = mimeType === "audio/mp4" ? "mp4" : mimeType === "audio/aac" ? "aac" : "webm";
      const audioBlob = new Blob(audioChunks, { type: mimeType }); // 使用实际格式创建 Blob
      const audioURL = URL.createObjectURL(audioBlob);
      setAudioBlob(audioBlob);
      setAudioURL(audioURL);

      // 使用 Audio 对象获取精确的音频时长
      const audioElement = new Audio(audioURL);
      audioElement.preload = "metadata"; // 仅加载元数据
      console.log("执行到录音结束");
      audioElement.onloadedmetadata = () => {
        if (audioElement.duration === Infinity) {
          // 解决 Infinity 的问题，获取正确的时长
          audioElement.currentTime = Number.MAX_SAFE_INTEGER;
          audioElement.ontimeupdate = () => {
            audioElement.ontimeupdate = null;
            const audioDuration = audioElement.duration;
            updateEstimatedReplyPoints(audioDuration);
          };
        } else {
          const audioDuration = audioElement.duration;
          updateEstimatedReplyPoints(audioDuration);
        }
      };
    };
  }
};

  // 更新预计积分消耗的函数，结合音频时长与文字内容
  const updateEstimatedReplyPoints = (audioDuration) => {
    let points = 0;

    // 计算文字积分
    for (const char of replyText) {
      points += /[\u4e00-\u9fa5]/.test(char) ? 5 : 1; // 汉字5积分，英文字符1积分
    }

    // 计算音频积分
    points += Math.round(audioDuration) * 10; // 每秒录音10积分

    setEstimatedReplyPoints(points);  // 更新预计积分消耗
  };

  // 删除录音
  const handleDeleteAudio = () => {
    const confirmDelete = window.confirm("确定要删除录音吗？");
    if (confirmDelete) {
      setAudioBlob(null);  // 删除录音的 Blob
      setAudioURL('');  // 清除音频 URL
    }
  };
// 提交回复
const handleReplySubmit = async (question_id) => {
  if (estimatedReplyPoints > remainingPoints) {
    alert("积分不足，无法提交");
    return;
  }
  const token = localStorage.getItem("token");
  const timestamp_id = "line-" + Date.now(); // 创建唯一的时间戳ID
  const formData = new FormData();

  formData.append("user_id", localStorage.getItem("userId")); // 回复者的ID
  formData.append("timestamp_id", timestamp_id);
  formData.append("question_text", replyText);
  formData.append("needTeacher", needTeacher);
  // 根据用户角色判断是否为提问者（学生）还是回答者（老师）
  const isQuestion = userRole === "teacher" ? false : true;
  const isResponded = userRole === "teacher" ? true : false;
  formData.append("is_question", isQuestion); // 动态设置 is_question
  const addIsResponded = (questionId, isResponded) => {
    setAllMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.question_id === questionId
          ? { ...msg, is_responded: isResponded }
          : msg
      )
    );
  };

  if (audioBlob) {
    const mimeType = mediaRecorderRef.current.mimeType; // 获取录音的 MIME 类型
    const extension = mimeType === "audio/mp4" ? "mp4" : mimeType === "audio/aac" ? "aac" : "webm";
    const audioFile = new File([audioBlob], `${timestamp_id}.${extension}`, { type: mimeType }); // 使用正确的扩展名
    formData.append("question_audio", audioFile);
  }

  try {
    const response = await axios.post(
      `${BACKEND_URL}/add_followup/${question_id}`,
      formData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("Reply submitted:", response.data);

    // 重置输入框和录音状态
    setReplyText("");
    setAudioBlob(null);
    setAudioURL(""); // 清除录音 URL
    setRecording(false);
    setRemainingPoints(response.data.round.user_remaining_points);
    // 更新本地状态，显示新的回复
    updateAllMessages({
      question_id,
      text: replyText,
      audio: audioBlob ? response.data.round.audio : null,
      timestamp: new Date().toISOString(),
      points: response.data.round.points,
      is_question: isQuestion,
      is_read: true,
    });
    if (isResponded) {
      addIsResponded(question_id, isResponded);
    }
  } catch (error) {
    console.error("Failed to submit reply:", error);
  }
};
  // 发送请求到后端，更新数据库中的 is_read 状态
  const toggleReadStatus = async (question_id, timestamp, newStatus) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `${BACKEND_URL}/update_read_status/`,
        {
          question_id,
          timestamp,  // 使用 timestamp 作为标识符
          is_read: newStatus  // 要更新的状态
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // 更新前端的状态
      setAllMessages((prevMessages) =>
        prevMessages.map((msg) => {
          if (msg.question_id === question_id) {
            const updatedConversation = msg.conversation.map((round) => {
              if (round.timestamp === timestamp) {
                // 更新对应消息的 is_read 状态
                return { ...round, is_read: newStatus };
              }
              return round;
            });
            return { ...msg, conversation: updatedConversation };
          }
          return msg;
        })
      );
    } catch (error) {
      console.error("Failed to update read status:", error);
    }
  };

  const calculateRemainingTimeInSeconds = (lastAssignedAt) => {
    const lastAssignedDate = new Date(lastAssignedAt);
    const currentTime = new Date(); // 当前系统时间
    const utcCurrentTime = new Date(currentTime.getTime() + currentTime.getTimezoneOffset() * 60000); // 将当前时间转换为 UTC
    const timeDifference = lastAssignedDate - utcCurrentTime;

    return timeDifference > 0 ? Math.floor(timeDifference) : 0; // 确保倒计时不为负数
  };

  const formatCountdown = (timeDifference, questionId) => {
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const remainingSeconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    if (minutes === 0 & remainingSeconds === 0) {
      console.log('第一步的');
      handleCountdownEnd(questionId);
    }
    return `${minutes}分${remainingSeconds}秒`;
  };

  const formatCountdownSecond = (timeInSeconds, questionId) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    if (minutes === 0 & seconds === 0) {
      console.log('第二步的');
      handleCountdownEnd(questionId);
    }

    return `${minutes}分${seconds}秒`;
  };

  const resetCountdown = async (questionId) => {
    try {
      await axios.post(`${BACKEND_URL}/reset_last_assigned_at`, { question_id: questionId });
      
      const newLastAssignedAt = new Date(Date.now() + 10 * 60 * 1000); // 当前时间 + 10 分钟
      setAllMessages((prevMessages) =>
        prevMessages.map((msg) => {
          if (msg.question_id === questionId) {
            return {
              ...msg,
              conversation: msg.conversation.map((round) => ({
                ...round,
                last_assigned_at: newLastAssignedAt.toISOString(),
              })),
            };
          }
          return msg;
        })
      );

      // 更新倒计时为 0 分钟（60 秒）
      setCountdown((prevCountdown) => ({
        ...prevCountdown,
        [questionId]: 60, // 1 分钟倒计时
      }));
    } catch (error) {
      console.error("Failed to reset countdown:", error);
    }
  };

  // 当倒计时为0时的处理函数
  const handleCountdownEnd = (questionId) => {
    const removedMessage = allMessages.find((msg) => msg.question_id === questionId);
    console.log('移除removedMessage:', removedMessage);
    console.log('移除时的questionId:', questionId);
    console.log('userRole:', userRole, 'removedMessage.auto_assign_teacher:', removedMessage.auto_assign_teacher, 'is_responded:', removedMessage.is_responded);
    if (removedMessage && userRole === "teacher" && removedMessage.auto_assign_teacher && (removedMessage.is_responded === false || removedMessage.is_responded === undefined)) {
      // 将移除的问题添加到 removedQuestions.current 中
      removedQuestions.current.push({
        question_id: removedMessage.question_id,
        last_assigned_at: removedMessage.last_assigned_at
      });
      console.log('移除时的removedQuestions:', removedQuestions.current);
  
      // 从 allMessages 中移除该问题
      setAllMessages((prevMessages) => prevMessages.filter((msg) => msg.question_id !== questionId));
  
      // 从 countdown 中移除该问题的倒计时状态
      setCountdown((prevCountdown) => {
        const updatedCountdown = { ...prevCountdown };
        delete updatedCountdown[questionId];
        return updatedCountdown;
      });
    }
  };

  const renderConversation = (conversation, question_id, last_assigned_at, userRole, toggleReadStatus) => {
    if (!Array.isArray(conversation)) {
      return <div>无对话内容</div>;
    }
  
    return conversation.map((round, index) => {
      const isMessageFromOther =
        (userRole === 'teacher' && round.is_question) ||
        (userRole === 'student' && !round.is_question);
  
      return (
        <div
          key={index}
          className={`chat-row ${round.is_question ? 'chat-row-right' : 'chat-row-left'}`}
        >
          {/* 左侧头像（老师） */}
          {!round.is_question && (
            <div className="chat-avatar-container avatar-left">
              <img
                src="https://test.fluensay.net/Userpics/1_avatar.png"
                alt="老师的头像"
                className="chat-avatar"
              />
            </div>
          )}
  
          {/* 消息气泡 */}
          <div className={`chat-bubble-container ${round.is_question ? 'bubble-right' : 'bubble-left'}`}>
            <div className="chat-bubble">
              <p className="chat-sender">
                {round.is_question ? '我' : '老师'} {round.timestamp}
              </p>
              <div className="chat-text">{round.text || '无文字内容'}</div>
              {round.audio && (
                <audio
                  controls
                  src={`${AUDIO_URL}/audio/${round.audio}?t=${new Date().getTime()}`}
                  className="chat-audio"
                />
              )}
              <p className="chat-points">消耗积分：{round.points}</p>
            </div>
          </div>
  
          {/* 右侧头像（我） */}
          {round.is_question && (
            <div className="chat-avatar-container avatar-right">
              <img
                src="https://test.fluensay.net/Userpics/2_avatar.png"
                alt="我的头像"
                className="chat-avatar"
              />
            </div>
          )}
        </div>
      );
    });
  };

  const updateAllMessages = (messages) => {
    console.log('allMessages:', allMessages);
    console.log('messages:', messages);
    console.log('removedQuestions:', removedQuestions.current);

    // 检查 messages 是否在 removedQuestions.current 中存在相同的 question_id 和 last_assigned_at
    const isMessageRemoved = removedQuestions.current.some(
      (removed) =>
        removed.question_id === messages.question_id &&
        removed.last_assigned_at === messages.last_assigned_at
    );

    if (isMessageRemoved) {
      console.log("Message matches removed question. Skipping update.");
      return;
    }

    // 检查是否已经存在相同的 text 和 timestamp_id
    const messageExists = allMessages.some(msg =>
      msg.question_id === messages.question_id &&
      msg.conversation.some(convo => convo.text === messages.text && convo.timestamp === messages.timestamp)
    );

    if (messageExists) {
      console.log("Message with the same text and timestamp_id already exists. Skipping update.");
      return;
    }

    setAllMessages((prevMessages) => {
      const questionIdExists = prevMessages.some(msg => msg.question_id === messages.question_id);

      if (questionIdExists) {
        return prevMessages.map((msg) => {
          if (msg.question_id === messages.question_id) {
            const newConversationItem = {
              text: messages.text,
              audio: messages.audio,
              timestamp: messages.timestamp,
              is_question: messages.is_question,
              is_read: messages.is_read,
              points: messages.points,
              last_assigned_at: messages.last_assigned_at
            };

            return {
              ...msg,
              conversation: [...msg.conversation, newConversationItem],
              is_closed: messages.is_closed,
              last_assigned_at: messages.last_assigned_at,
              auto_assign_teacher: messages.auto_assign_teacher,
            };
          }
          return msg;
        });
      } else {
        const newMessage = {
          question_id: messages.question_id,
          timestamp_id: messages.timestamp_id,
          conversation: [
            {
              text: messages.text,
              audio: messages.audio,
              timestamp: messages.timestamp,
              is_question: messages.is_question,
              is_read: messages.is_read,
              points: messages.points,
              last_assigned_at: messages.last_assigned_at,
            },
          ],
          is_closed: messages.is_closed,
          last_assigned_at: messages.last_assigned_at,
          auto_assign_teacher: messages.auto_assign_teacher,
          question_title: messages.text,
        };

        return [...prevMessages, newMessage];
      }
    });

    setNewMessageFlag((prevNewMessageFlag) => ({
      ...prevNewMessageFlag,
      [messages.question_id]: true,
    }));
  };

  return (
    <div className="messages-container">
      <h1>我的消息</h1>
      <>
      <h2>提问老师</h2>
      <QuestionButton
            timestampId={'timestamp_id'}
            questions={questions}
            isQuestionExpanded={isQuestionExpanded}
            isAskingQuestion={isAskingQuestion}
            handleQuestionToggle={handleQuestionToggle}
        />
        {isAskingQuestion['timestamp_id'] && (
        <QuestionForm
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionAudio={questionAudio}
            setQuestionAudio={setQuestionAudio}
            handleSubmitQuestion={handleSubmitQuestion}
            timestampId={'timestamp_id'}
            startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
            stopQuestionRecording={stopQuestionRecording}
            remainingPoints={remainingPoints}
            estimatedPoints = {estimatedPoints}
            handleTextChange = {handleTextChange}
            renderAudio={renderAudio}
            pendingAudio={pendingAudio}
            setAssignTeacher={setAssignTeacher}
            assignTeacher={assignTeacher}
            needTeacher={needTeacher}
        />
        )}
        {isQuestionExpanded['timestamp_id'] && (
        <AskQuestions
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionAudio={questionAudio}
            setQuestionAudio={setQuestionAudio}
            handleSubmitQuestion={handleSubmitQuestion}
            timestampId={'timestamp_id'}
            startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
            stopQuestionRecording={stopQuestionRecording}
            remainingPoints={remainingPoints}
            estimatedPoints = {estimatedPoints}
            handleTextChange = {handleTextChange}
            renderAudio={renderAudio}
            pendingAudio={pendingAudio}
            qTimestampId={'qtimestamp_id'}
            followupText={followupText}
            handleSubmitFollowup={handleSubmitFollowup}
            setFollowupText={setFollowupText}
            questions={questions}
            needTeacher={needTeacher}
        />
        )}    
      </>
      {allMessages.length === 0 ? (
        <p>没有消息。</p>
      ) : (
        <ul>
        {allMessages.map((msg) => {
          const questionHasUnread = msg.conversation.some(
            (round) => !round.is_read && ((userRole === 'teacher' && round.is_question) || (userRole === 'student' && !round.is_question))
          );

          const expirationTimeDisplay = msg.last_assigned_at && userRole === "teacher" && msg.auto_assign_teacher && !msg.is_responded ? (
            <span className="expiration-time">
              <strong>到期时间:</strong> {countdown[msg.question_id] !== undefined
                ? formatCountdownSecond(countdown[msg.question_id], msg.question_id)
                : formatCountdown(calculateRemainingTimeInSeconds(msg.last_assigned_at), msg.question_id)}
            </span>
          ) : null;

          return (
            <li key={msg.question_id} className="message-item">
              <button
                onClick={() => toggleExpand(msg.question_id)}
                className="message-title-button full-width-button"
                style={{ color: msg.questionHasUnread ? 'red' : 'gray' }}
              >
                {expanded[msg.question_id] ? '收起' : '展开'} 问题: {msg.question_title}
              </button>
        
              {expanded[msg.question_id] && (
                <div className="expanded-content">
                  <p>
                    <strong>时间戳:</strong> {msg.timestamp_id}
                  </p>
                  <div>
                    <strong>对话:</strong>
                    <div className="chat-messages">
                      {renderConversation(
                        msg.conversation,
                        msg.question_id,
                        msg.last_assigned_at,
                        userRole,
                        toggleReadStatus
                      )}
                    </div>
                  </div>
        
                  <textarea
                    value={replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    placeholder="输入回复内容..."
                    className="reply-textarea"
                  />
        
                  <button
                    id={msg.question_id}
                    onMouseDown={() => {
                      document.getElementById(msg.question_id).innerText = '录音中...';
                      startRecording();
                    }}
                    onMouseUp={() => {
                      document.getElementById(msg.question_id).innerText = '重新录音';
                      stopRecording();
                    }}
                    className="record-button"
                  >
                    按住录音
                  </button>
        
                  {audioURL && (
                    <div className="audio-controls">
                      <audio controls src={audioURL} />
                      <button onClick={handleDeleteAudio} className="submit-button">
                        删除音频
                      </button>
                    </div>
                  )}
        
                  <p>剩余积分：{remainingPoints}</p>
                  <p>预计积分消耗：{estimatedReplyPoints}</p>
        
                  <button
                    onClick={() => handleReplySubmit(msg.question_id)}
                    className="submit-button"
                    disabled={estimatedReplyPoints > remainingPoints}
                  >
                    {estimatedReplyPoints > remainingPoints ? '积分不足，无法提交' : '提交'}
                  </button>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      )}
      <>
      <h2>咨询客服</h2>
      <QuestionButton
            timestampId={'no_teacher'}
            questions={questions}
            isQuestionExpanded={isQuestionExpanded}
            isAskingQuestion={isAskingQuestion}
            handleQuestionToggle={handleQuestionToggle}
        />
        {isAskingQuestion['no_teacher'] && (
        <QuestionForm
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionAudio={questionAudio}
            setQuestionAudio={setQuestionAudio}
            handleSubmitQuestion={handleSubmitQuestion}
            timestampId={'no_teacher'}
            startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
            stopQuestionRecording={stopQuestionRecording}
            remainingPoints={remainingPoints}
            estimatedPoints = {estimatedPoints}
            handleTextChange = {handleTextChange}
            renderAudio={renderAudio}
            pendingAudio={pendingAudio}
            setAssignTeacher={setAssignTeacher}
            assignTeacher={assignTeacher}
            needTeacher={false}
        />
        )}
        {isQuestionExpanded['no_teacher'] && (
        <AskQuestions
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionAudio={questionAudio}
            setQuestionAudio={setQuestionAudio}
            handleSubmitQuestion={handleSubmitQuestion}
            timestampId={'no_teacher'}
            startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
            stopQuestionRecording={stopQuestionRecording}
            remainingPoints={remainingPoints}
            estimatedPoints = {estimatedPoints}
            handleTextChange = {handleTextChange}
            renderAudio={renderAudio}
            pendingAudio={pendingAudio}
            qTimestampId={'no_teacher'}
            followupText={followupText}
            handleSubmitFollowup={handleSubmitFollowup}
            setFollowupText={setFollowupText}
            questions={questions}
            needTeacher={false}
        />
        )}    
      </>
    </div>
  );
}

export default MyMessagesPage;