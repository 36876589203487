import React from 'react';
import './AskQuestions.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
const AUDIO_URL = BACKEND_URL.split("/api")[0];

export const AskQuestions = ({
  followupText,
  timestampId,
  setFollowupText,
  estimatedPoints,
  remainingPoints,
  handleTextChange,
  qTimestampId,
  startQuestionRecording,
  stopQuestionRecording,
  pendingAudio,
  renderAudio,
  handleSubmitFollowup,
  questions,
  needTeacher,
}) => (
  <div className="chat-container">
    <div className="chat-messages">
      {questions[timestampId]?.conversation?.map((round, i) => (
        round ? (
          <div
            key={i}
            className={`chat-row ${round.is_question ? 'chat-row-right' : 'chat-row-left'}`}
          >
            {/* 头像 */}
            {!round.is_question && (
              <div className="chat-avatar-container avatar-left">
                <img
                  src="https://test.fluensay.net/Userpics/1_avatar.png"
                  alt="老师的头像"
                  className="chat-avatar"
                />
              </div>
            )}

            {/* 消息气泡 */}
            <div className={`chat-bubble-container ${round.is_question ? 'bubble-right' : 'bubble-left'}`}>
              <div className="chat-bubble">
                <p className="chat-sender">
                  {round.is_question ? '我' : '老师'} {round.timestamp}
                </p>
                <div className="chat-text">{round.text}</div>
                {round.audio && (
                  <audio controls src={`${AUDIO_URL}/audio/${round.audio}?t=${new Date().getTime()}`} />
                )}
                <p className="chat-points">消耗积分：{round.points}</p>
              </div>
            </div>

            {/* 头像 */}
            {round.is_question && (
              <div className="chat-avatar-container avatar-right">
                <img
                  src="https://test.fluensay.net/Userpics/2_avatar.png"
                  alt="我的头像"
                  className="chat-avatar"
                />
              </div>
            )}
          </div>
        ) : null
      ))}
    </div>

    {/* 补充说明输入区 */}
    <div className="chat-input">
      <textarea
        value={followupText[timestampId] || ''}
        onChange={(e) => {
          setFollowupText({ ...followupText, [timestampId]: e.target.value });
          handleTextChange(e.target.value);
        }}
        placeholder="请输入补充说明"
        className="chat-textarea"
      />
      <div className="chat-actions">
        <button
          id={qTimestampId}
          onMouseDown={() => {
            document.getElementById(qTimestampId).innerText = "录音中...";
            startQuestionRecording(qTimestampId);
          }}
          onMouseUp={() => {
            document.getElementById(qTimestampId).innerText = "重新录音";
            stopQuestionRecording(qTimestampId);
          }}
          className="chat-record-button"
        >
          {pendingAudio && pendingAudio.timestampId === timestampId ? '重新录音' : '录音'}
        </button>
        {renderAudio(qTimestampId, timestampId)}
        <button
          onClick={() => handleSubmitFollowup(timestampId)}
          disabled={estimatedPoints > remainingPoints}
          className="chat-submit-button"
        >
          {estimatedPoints > remainingPoints ? "积分不足，无法提交" : "提交补充说明"}
        </button>
      </div>
    </div>
    <div className="chat-footer">
      <p>剩余积分: {remainingPoints}</p>
      <p>预计积分消耗: {estimatedPoints}</p>
    </div>
  </div>
);
