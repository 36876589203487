// src/services/authService.js
import axios from 'axios';

export const refreshAccessToken = async () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  try {
    console.log("Attempting to refresh token with:", localStorage.getItem("refresh_token"));
    const response = await axios.post(
      `${BACKEND_URL}/token/refresh/`, 
      { token: localStorage.getItem('refresh_token') },
      { 
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',   // 禁用缓存
          'Pragma': 'no-cache'           // 兼容处理
        }, 
        withCredentials: true 
      }
    );
    console.log("Refresh response:", response);

    const newToken = response.data.access_token;
    localStorage.setItem("token", newToken);
    console.log("Token refreshed successfully:", newToken);
    return newToken;
  } catch (error) {
    console.error("Failed to refresh token in refreshAccessToken:", error.response || error.message || error);
    throw error;
  }
};


export const isTokenExpired = (token) => {
  if (!token) return true;
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const payload = JSON.parse(atob(token.split('.')[1]));  // 解析 token 的 payload
  const currentTime = Date.now() / 1000;
  
  return payload.exp < currentTime;
};

// 使用 token 时，检查是否需要刷新
export const useAccessToken = async () => {
  const token = localStorage.getItem('token');
  if (isTokenExpired(token)) {
    await refreshAccessToken();  // 如果过期，刷新 token
  }
};
