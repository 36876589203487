import React, { useState, useEffect } from 'react';

function ChooseTeacherPage() {
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  // Fetch all teachers and the current student's assigned teacher when the component loads
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert("您还没有登录，请先登录");
      return;
    }
  
    // Fetch all teachers and current student info
    Promise.all([
      fetch(`${BACKEND_URL}/teachers`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => {
        if (!res.ok) throw new Error("Unauthorized");
        return res.json();
      }),
      fetch(`${BACKEND_URL}/students/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(res => res.json())
    ])
      .then(([teachersData, studentData]) => {
        setTeachers(teachersData);
        setSelectedTeacher(studentData.assigned_teacher_id);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setTeachers([]); // 避免 teachers 为 null
      });
  }, []);

  // Handle selection change
  const handleTeacherSelect = (event) => {
    setSelectedTeacher(parseInt(event.target.value));  // Convert the selected teacher ID to an integer
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    
    if (!token) {
      alert("您还没有登录，请先登录");
      return;
    }

    fetch(`${BACKEND_URL}/students/assign-teacher`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ teacher_id: selectedTeacher }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Authorization failed');
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          alert('老师选择成功');
          // 这里不需要刷新页面，只要保持状态即可
        }
      })
      .catch((error) => console.error('Error submitting teacher selection:', error));
  };

  return (
    <div>
      <h2>选择您的老师</h2>
      <form onSubmit={handleSubmit}>
        {teachers.map((teacher) => (
          <div key={teacher.id}>
            <label>
              <input
                type="radio"
                name="teacher"
                value={teacher.id}
                checked={selectedTeacher === teacher.id}  // Check if the teacher is selected
                onChange={handleTeacherSelect}
              />
              {teacher.email}
            </label>
          </div>
        ))}
        <button type="submit" disabled={!selectedTeacher}>提交</button>
      </form>
    </div>
  );
}

export default ChooseTeacherPage;
