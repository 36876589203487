import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MyCourses = ({ setCurrentPage, setWordListPage, setFileName }) => {
    const [files, setFiles] = useState([]);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/files`);
            const sortedFiles = response.data.sort((a, b) => a.order - b.order);  // 按 order 排序
            setFiles(sortedFiles);
        } catch (error) {
            console.error('Error fetching files', error);
        }
    };

    return (
        <div>
            <h1>我的课程</h1>
            {files.map(file => {
                const isPublished = file.word_overview_published || file.student_page_published || file.study_word_published || file.srt_published;
                if (!isPublished) return null; 
    
                return (
                    <div key={file.filename} style={{ marginBottom: '20px' }}>
                        <h2>{file.filename}</h2>
                        <ul className="button-list">
                            {file.word_overview_published && (
                                <li className="button-item">
                                    <button className="btn-small" onClick={() => {
                                        setFileName(`word_${file.filename}`);
                                        setWordListPage(file.filename);
                                        setCurrentPage('word-overview');
                                    }}>
                                        单词一览
                                    </button>
                                </li>
                            )}
                            {file.student_page_published && (
                                <li className="button-item">
                                    <button className="btn-small" onClick={() => {
                                        setFileName(file.filename);
                                        setCurrentPage('student-page');
                                    }}>
                                        学生详解页
                                    </button>
                                </li>
                            )}
                            {file.study_word_published && (
                                <li className="button-item">
                                    <button className="btn-small" onClick={() => {
                                        setFileName(`word_${file.filename}`);
                                        setWordListPage(file.filename);
                                        setCurrentPage('study-words');
                                    }}>
                                        背单词
                                    </button>
                                </li>
                            )}
                            {file.srt_published && (
                                <li className="button-item">
                                    <button
                                        className="btn-small"
                                        onClick={async () => {
                                            const token = localStorage.getItem('token'); // 获取 JWT 令牌
                                            if (!token) {
                                                alert("请先登录以下载字幕文件");
                                                return;
                                            }

                                            try {
                                                const response = await axios.get(`${BACKEND_URL}/download/${file.filename}`, {
                                                    headers: {
                                                        Authorization: `Bearer ${token}`,
                                                    },
                                                    responseType: 'blob', // 确保文件以二进制数据格式接收
                                                });

                                                // 创建下载链接
                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', file.filename); // 设置文件名
                                                document.body.appendChild(link);
                                                link.click();
                                                link.remove();
                                            } catch (error) {
                                                console.error("Error downloading file:", error);
                                                alert("下载失败，请稍后再试");
                                            }
                                        }}
                                    >
                                        下载字幕文件
                                    </button>
                                </li>
                            )}

                        </ul>
                    </div>
                );
            })}
        </div>
    );
    
};

export default MyCourses;
