import { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export const useQuestions = (fileName = null, userId, needTeacher) => {
  const [questions, setQuestions] = useState({});
  const [isAskingQuestion, setIsAskingQuestion] = useState({});
  const [isQuestionExpanded, setIsQuestionExpanded] = useState({});
  const [questionText, setQuestionText] = useState('');
  const [questionAudio, setQuestionAudio] = useState(null);
  const [followupText, setFollowupText] = useState({});
  const [followupAudio, setFollowupAudio] = useState({});
  const [isDisable, setIsDisable] = useState({});
  const [assignTeacher, setAssignTeacher] = useState(true);  // 切换是否指定老师
  const [assignedTeacherId, setAssignedTeacherId] = useState(null);
  const [pendingAudio, setPendingAudio] = useState(null);
  const [estimatedPoints, setEstimatedPoints] = useState(0); // 新增状态用于存储预计积分消耗
  const [remainingPoints, setRemainingPoints] = useState(0); // 新增状态用于存储用户剩余积分
  let mediaRecorder = null;
  let audioChunks = [];
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  // 获取用户积分信息
  useEffect(() => {
    if (userId) {
      axios.get(`${BACKEND_URL}/users/${userId}/points`)
        .then(response => {
          const { remaining_points } = response.data;
          setRemainingPoints(remaining_points); // 设置剩余积分
        })
        .catch(error => {
          console.error("Error fetching user points:", error);
        });
    }
  }, [userId]);

  const fetchQuestions = async () => {
    console.log('执行到了：')
    if (fileName) {
        console.log('userId2:', userId, 'fileName:', fileName);
        try {
          const questionsResponse = await axios.get(`${BACKEND_URL}/get_questions/${fileName}/${userId}`);
          const questionsData = questionsResponse.data.reduce((acc, question) => {
            // 解析 conversation 字段为数组
            const conversation = typeof question.conversation === 'string'
              ? JSON.parse(question.conversation)
              : question.conversation;
    
            acc[question.timestamp_id] = {
              ...question,
              conversation
            };
            return acc;
          }, {});
          setQuestions(questionsData);
        } catch (error) {
          console.error('Error fetching questions:', error);
        }
    }

  };

  const calculateEstimatedPoints = (text = '', audioDuration = 0) => {
    let points = 0;

    // 计算文字积分
    for (const char of text) {
      points += /[\u4e00-\u9fa5]/.test(char) ? 5 : 1; // 汉字每个5积分，英文每字符1积分
    }

    // 计算音频积分
    points += audioDuration * 10; // 每秒音频消耗10积分

    return Math.floor(points);;
  };

  const handleTextChange = (text) => {
    setQuestionText(text);
    const points = calculateEstimatedPoints(text, questionAudio?.duration || 0);
    setEstimatedPoints(points);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    const getUserIdFromToken = () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        return decodedToken.id;
      }
      return null;
    };
  
    // 定义异步函数
    if (needTeacher) {
        const fetchAssignedTeacherId = async () => {
            try {
              const response = await axios.get(`${BACKEND_URL}/students/me`, {
                headers: {
                  'Authorization': `Bearer ${token}`  // 在请求头中添加 token
                }
              });
        
              // 成功获取返回的数据
              setAssignedTeacherId(response.data.assigned_teacher_id);
            } catch (error) {
              console.error('Error fetching assigned teacher ID:', error);
            }
          };
        // 调用异步函数
        fetchAssignedTeacherId();
    }

  

  }, []);  // [] 表示只在组件挂载时执行

  const handleSubmitQuestion = async (timestampId) => {
    let textCheck = 0;
    for (const char of questionText) {
      textCheck += /[\u4e00-\u9fa5]/.test(char) ? 2 : 1; // 至少5个汉字或者10个字母才允许提交
    }
    if (textCheck<10) {
      alert('第一轮提问文字不能太短');
      return;
    }

    if (estimatedPoints > remainingPoints) {
      alert("积分不足，无法提交问题");
      return;
    }

    if (needTeacher && assignTeacher && !assignedTeacherId) {
      alert('你还未选择指定老师，请先到个人主页指定老师，否则只能选择自动分配老师');
      return;
    }
  
    const formData = new FormData();
    formData.append('fileName', fileName);
    formData.append('timestamp_id', timestampId);
    formData.append('question_text', questionText || '');
    formData.append('user_id', userId);
    formData.append('is_question', true);
    formData.append('needTeacher', needTeacher);
    console.log('timestamp_id:', timestampId, 'userId:', userId);
  
    if (needTeacher && assignTeacher && assignedTeacherId) {
      formData.append('assigned_teacher_id', assignedTeacherId);
    }
  
    if (questionAudio) {
      const audioFilename = `${timestampId}_${userId}.mp4`;
      formData.append('question_audio', questionAudio.audioBlob, audioFilename);
    }
  
    try {
      const response = await axios.post(`${BACKEND_URL}/submit_question`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      const currentTimestamp = new Date().toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
  
      setQuestions((prevQuestions) => ({
        ...prevQuestions,
        [timestampId]: {
          ...(prevQuestions[timestampId] || { conversation: [] }),
          conversation: [
            ...(prevQuestions[timestampId]?.conversation || []),
            {
              timestamp: currentTimestamp,
              text: response.data.question_text,
              audio: response.data.question_audio,
              is_question: response.data.is_question,
              points: response.data.points,
            },
          ],
          question_id: response.data.question_id,
        },
      }));
      setRemainingPoints(response.data.user_remaining_points);
      setIsAskingQuestion({ ...isAskingQuestion, [timestampId]: false });
      setIsQuestionExpanded({ ...isQuestionExpanded, [timestampId]: true });
      setQuestionText('');
      setQuestionAudio(null);
      setIsDisable({ ...isDisable, [timestampId]: true });
    } catch (error) {
      console.error('Error submitting question:', error);
      alert('提交问题失败');
    }
  };
  

  const handleSubmitFollowup = async (timestampId) => {
    if (estimatedPoints > remainingPoints) {
      alert("积分不足，无法提交补充说明");
      return;
    }
    console.log('timestampId:', timestampId);
    const question = questions[timestampId];
    console.log('questions:', questions);
    if (!question || !question.question_id) {
      alert('未找到对应的问题');
      return;
    }
  
    const text = followupText[timestampId];  // 获取对应 timestampId 的文本
  
    if (!text && !questionAudio) {
      alert('请输入文字或录音');
      return;
    }
  
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('question_text', text);
    formData.append('timestamp_id', timestampId);
    formData.append('is_question', true);
    formData.append('needTeacher', needTeacher);
    if (questionAudio) {
      const audioFilename = `${timestampId}_${userId}.mp4`;
      formData.append('question_audio', questionAudio.audioBlob, audioFilename);
    }
  
    try {
      const response = await axios.post(`${BACKEND_URL}/add_followup/${question.question_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setQuestions((prevQuestions) => ({
        ...prevQuestions,
        [timestampId]: {
          ...prevQuestions[timestampId],
          conversation: [
            ...(prevQuestions[timestampId]?.conversation || []),
            response.data.round,  // 新的补充说明
          ],
        },
      }));
      setRemainingPoints(response.data.round.user_remaining_points);
      // 清除提交后的对应 followupText 和 followupAudio
      setFollowupText((prevText) => ({ ...prevText, [timestampId]: '' }));
      setQuestionAudio(null);
  
    } catch (error) {
      console.error('Error submitting follow-up:', error);
      alert('提交补充说明失败');
    }
  };

  const handleQuestionToggle = (timestampId) => {
    if (questions[timestampId]) {
        // 如果问题已经提交过，则切换“收起提问”和“展开提问”
        setIsQuestionExpanded((prevState) => ({
          ...prevState,
          [timestampId]: !prevState[timestampId]
        }));
      } else {
        // 如果问题还没有提交过，则控制“提问”和“关闭提问”
        setIsAskingQuestion(prevState => ({ 
          ...prevState, 
          [timestampId]: !prevState[timestampId]
        }));
    }
  };

  // 开始录音
  const startQuestionRecording = async (timestampId) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // 确定支持的 MIME 类型
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : MediaRecorder.isTypeSupported("audio/aac")
        ? "audio/aac"
        : "audio/webm";

      mediaRecorder = new MediaRecorder(stream, { mimeType });
      audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.start();
      console.log("开始录音，使用格式:", mimeType);
    } catch (err) {
      console.error("Error accessing microphone:", err);
      alert("无法访问麦克风，请检查权限设置");
    }
  };

  // 结束录音并处理音频
  const stopQuestionRecording = (timestampId) => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        const mimeType = mediaRecorder.mimeType; // 获取实际 MIME 类型
        const extension = mimeType === "audio/mp4" ? "mp4" : mimeType === "audio/aac" ? "aac" : "webm";
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        const audioURL = URL.createObjectURL(audioBlob);

        // 使用 Audio 对象获取精确的音频时长
        const audioElement = new Audio(audioURL);
        audioElement.preload = "metadata"; // 只加载元数据

        audioElement.onloadedmetadata = () => {
          if (audioElement.duration === Infinity) {
            audioElement.currentTime = Number.MAX_SAFE_INTEGER;
            audioElement.ontimeupdate = () => {
              audioElement.ontimeupdate = null;
              const audioDuration = audioElement.duration;
              setQuestionAudio({ timestampId, audioBlob, audioURL, duration: audioDuration });
              const points = calculateEstimatedPoints(questionText, audioDuration);
              setEstimatedPoints(points);
            };
          } else {
            const audioDuration = audioElement.duration;
            setQuestionAudio({ timestampId, audioBlob, audioURL, duration: audioDuration });
            const points = calculateEstimatedPoints(questionText, audioDuration);
            setEstimatedPoints(points);
          }
        };
      };
    }
  };

  

  const renderAudio = (timestampId) => {
    if (questionAudio && questionAudio.timestampId === timestampId) {
      return (
        <div>
          <audio controls src={questionAudio.audioURL} />
          <button onClick={() => setQuestionAudio(null)}>删除</button>
        </div>
      );
    } else if (!questionAudio) {
      return null;
    }
    return null;  // 如果没有学生录音，不显示音频控件
  };

  return {
    questions,
    isAskingQuestion,
    isQuestionExpanded,
    questionText,
    questionAudio,
    remainingPoints, // 返回 remainingPoints
    setRemainingPoints,
    estimatedPoints, // 暴露预计积分状态
    handleTextChange, // 新的文字输入处理函数
    followupText,
    followupAudio,
    handleQuestionToggle,
    fetchQuestions,
    setQuestionText,
    setQuestionAudio,
    setFollowupText,
    setFollowupAudio,
    handleSubmitQuestion,
    startQuestionRecording,
    stopQuestionRecording,
    renderAudio,
    handleSubmitFollowup,
    setAssignTeacher,
    assignTeacher,
    setQuestions,
  };
};
