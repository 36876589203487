import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Home.css';

function Home({ setCurrentPage, userId }) {
  const [points, setPoints] = useState({ total: 0, used: 0, remaining: 0 });
  const [redeemCodes, setRedeemCodes] = useState([]);
  const [redeemCodeInput, setRedeemCodeInput] = useState("");
  const [redeemMessage, setRedeemMessage] = useState("");
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  useEffect(() => {
    if (userId) {
      axios.get(`${BACKEND_URL}/users/${userId}/points`)
        .then(response => {
          const { total_points, used_points, remaining_points, redeem_codes } = response.data;
          setPoints({
            total: total_points,
            used: used_points,
            remaining: remaining_points
          });
          setRedeemCodes(redeem_codes);
        })
        .catch(error => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [userId]);

  const handleRedeemCodeChange = (e) => {
    setRedeemCodeInput(e.target.value);
  };

  const handleRedeemCode = () => {
    axios.post(`${BACKEND_URL}/redeem-code`, {
      user_id: userId,
      code: redeemCodeInput
    })
      .then(response => {
        setRedeemMessage("兑换成功！积分已添加。");
        setPoints(response.data.points);
        const newRedeemCode = response.data.redeem_code;
        setRedeemCodes(prevCodes => [...prevCodes, newRedeemCode]);
        setRedeemCodeInput("");
      })
      .catch(error => {
        setRedeemMessage("兑换码无效或已使用。");
        console.error("Error redeeming code:", error);
      });
  };

  return (
    <div className="home-container">
      <h1>欢迎来到主页</h1>

      <div className="points-section">
        <h3>积分信息</h3>
        <p>总积分: {points.total}</p>
        <p>已消耗积分: {points.used}</p>
        <p>剩余积分: {points.remaining}</p>
      </div>

      <div className="redeem-codes-section">
        <h3>兑换码</h3>
        {redeemCodes.length > 0 ? (
          <ul>
            {redeemCodes.map((codeInfo, index) => (
              <li key={index}>
                <strong>兑换码:</strong> {codeInfo.code} |
                <strong> 积分:</strong> {codeInfo.points} |
                <strong> 兑换时间:</strong> {codeInfo.redeem_date ? new Date(codeInfo.redeem_date).toLocaleString() : "未兑换"}
              </li>
            ))}
          </ul>
        ) : (
          <p>没有兑换记录</p>
        )}
      </div>

      <div className="redeem-section">
        <h3>兑换积分</h3>
        <div className="redeem-input-container">
          <input
            type="text"
            value={redeemCodeInput}
            onChange={handleRedeemCodeChange}
            placeholder="输入兑换码"
          />
          <button onClick={handleRedeemCode}>兑换</button>
        </div>
        {redeemMessage && <p className="redeem-message">{redeemMessage}</p>}
      </div>

      <div className="action-buttons">
        <button onClick={() => setCurrentPage('user-profile')}>个人资料</button>
        <button onClick={() => setCurrentPage('invitation')}>邀请码</button>
      </div>
    </div>
  );
}

export default Home;
