import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useQuestions } from './useQuestions';
import { QuestionButton } from './QuestionButton';
import { QuestionForm } from './QuestionForm';
import { AskQuestions } from './AskQuestions';

const WordOverview = ({ fileName, wordListPage, userId }) => {
  const [words, setWords] = useState([]);  // 存储所有单词
  const [masteredWords, setMasteredWords] = useState([]);  // 存储熟记的单词
  const [unmasteredWords, setUnmasteredWords] = useState([]);  // 存储未熟记的单词
  const [showMastered, setShowMastered] = useState(false);  // 控制是否显示熟词本
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);  // 用于播放未提交的录音
  const [loading, setLoading] = useState(true);  // 加载状态
  const [submittedAudioUrl, setSubmittedAudioUrl] = useState(null);  // 提交后的音频文件
  const [audioWords, setAudioWords] = useState([]);
  const needTeacher = true;

  const {
    questions,
    isAskingQuestion,
    isQuestionExpanded,
    questionText,
    setQuestionText,
    questionAudio,
    setQuestionAudio,
    handleQuestionToggle,
    handleSubmitQuestion,
    fetchQuestions,
    startQuestionRecording,
    stopQuestionRecording,
    remainingPoints,
    estimatedPoints,
    handleTextChange,
    renderAudio,
    pendingAudio,
    followupText,
    handleSubmitFollowup,
    setFollowupText,
    setAssignTeacher,
    assignTeacher,
  } = useQuestions(fileName, userId, needTeacher);
  let mediaRecorder = null;
  let audioChunks = [];
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  const AUDIO_URL = BACKEND_URL.split("/api")[0];
  useEffect(() => {
    fetchQuestions();
  }, []);  // [] 表示只在组件挂载时执行

  useEffect(() => {
    if (!userId) {
      alert('请先登录');
      return;
    }
  
    const fetchWords = async () => {
      try {
        // 调用 /word_list/ 获取数据
        const wordListResponse = await axios.get(`${BACKEND_URL}/word_list/${wordListPage}`);
        const { words: baseForms, saved_selections: savedSelections } = wordListResponse.data;
  
        // 确保 baseForms 和 savedSelections 有效
        const baseFormsArray = Array.isArray(baseForms) ? baseForms : [];
        const savedSelectionsObj = savedSelections || {};
  
        // 筛选出 saved_selections 中的单词，并按 baseForms 顺序排序
        const sortedWords = baseFormsArray
          .filter((word) => savedSelectionsObj[word]) // 筛选 baseForms 中存在于 saved_selections 的单词
          .map((word) => {
            const data = savedSelectionsObj[word];
            return {
              word,
              meanings: data.selected_meanings || "", // 解释
              audio: data.selected_audios || {},      // 音频
              pronunciation: data.pronunciation || "" // 音标
            };
          });
  
        // 将 saved_selections 中 baseForms 不包含的单词排在后面
        const extraWords = Object.entries(savedSelectionsObj)
          .filter(([word]) => !baseFormsArray.includes(word)) // 筛选出不在 baseForms 中的单词
          .map(([word, data]) => ({
            word,
            meanings: data.selected_meanings || "",
            audio: data.selected_audios || {},
            pronunciation: data.pronunciation || ""
          }));
  
        // 合并结果
        const allWords = [...sortedWords, ...extraWords];
  
        // 获取当前用户的单词背诵记录
        const userWordsResponse = await axios.get(`${BACKEND_URL}/student_words/${userId}`);
        const userWords = userWordsResponse.data;
  
        // 使用 reduce 构建以 word 为键，audio 为值的对象
        const audioMap = userWords.reduce((acc, item) => {
          if (item.base_form && item.audio) {
            acc[item.base_form] = item.audio;
          }
          return acc;
        }, {});
        setAudioWords(audioMap);
  
        // 将单词分类为生词本和熟词本
        const mastered = [];
        const unmastered = [];
  
        allWords.forEach((word) => {
          const userWord = userWords.find((uw) => uw.base_form === word.word);
          if (userWord) {
            word.review_count = userWord.review_count || 0; // 背诵次数
            if (userWord.is_mastered) {
              mastered.push(word);
            } else {
              unmastered.push(word);
            }
          } else {
            // 如果用户没有背诵记录，则默认为生词本
            word.review_count = 0;
            unmastered.push(word);
          }
        });
  
        // 更新状态
        setWords(allWords);
        setMasteredWords(mastered);
        setUnmasteredWords(unmastered);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching words:', error);
        setLoading(false);
      }
    };
  
    fetchWords();
  }, [wordListPage, userId]);
  

  if (loading) {
    return <div>加载中...</div>;
  }

  const getAudioFileUrl = async (filename) => {
      const token = localStorage.getItem("token");
      if (!token) {
          alert("请先登录以播放音频");
          return null;
      }

      try {
          const response = await axios.get(`${BACKEND_URL}/audio/${filename}`, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
              responseType: "blob", // 确保以二进制格式获取文件
          });

          // 创建 Blob URL 供音频播放
          const url = window.URL.createObjectURL(new Blob([response.data]));
          return url;
      } catch (error) {
          console.error("Error fetching audio file:", error);
          alert("无法加载音频文件，请稍后再试");
          return null;
      }
  };

  // 在需要播放音频的地方调用
  const playAudio = async (filename) => {
      const audioUrl = await getAudioFileUrl(filename);
      if (audioUrl) {
          const audio = new Audio(audioUrl);
          audio.play();
      }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      // 检查并设置支持的 MIME 类型
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : MediaRecorder.isTypeSupported("audio/aac")
        ? "audio/aac"
        : "audio/webm";
  
      mediaRecorder = new MediaRecorder(stream, { mimeType });
      audioChunks = [];
  
      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };
  
      mediaRecorder.start();
      console.log("开始录音，使用格式:", mimeType);
    } catch (err) {
      console.error("无法启动录音:", err);
      alert("无法启动录音，请检查浏览器支持和麦克风设置。");
    }
  };
  
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        const mimeType = mediaRecorder.mimeType; // 获取实际使用的格式
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        setAudioBlob(audioBlob);
        setAudioUrl(URL.createObjectURL(audioBlob));
        console.log("录音完成，格式:", mimeType);
      };
    }
  };
  
  
  const handleUploadAudio = async (word) => {
    if (!audioBlob) return;
  
    // 确定扩展名
    const extension = audioBlob.type.includes("mp4") ? "mp4" : "aac";
    const formData = new FormData();
    formData.append("file", audioBlob, `${word}_${userId}_1.${extension}`);
  
    try {
      const response = await axios.post(`${BACKEND_URL}/upload_card_audio/${userId}/${word}`, formData);
      const audioUrl = `${BACKEND_URL}/audio/${response.data.filename}`;
      console.log("response.data.filename:", response.data.filename);
      setAudioWords((prevAudioWords) => ({
        ...prevAudioWords,
        [word]: response.data.filename,
      }));
      setAudioUrl(null); // 提交后清空录音区域的音频
      alert("录音已上传");
    } catch (error) {
      console.error("录音上传失败", error);
    }
  };
  
  const handleDeleteAudio = async (word) => {
    const confirmDelete = window.confirm('是否确认删除该音频？');
    if (confirmDelete) {
      try {
        await axios.delete(`${BACKEND_URL}/delete_card_audio/${userId}/${word}`);
        setAudioWords((prevAudioWords) => {
          const updatedAudioWords = { ...prevAudioWords };
          delete updatedAudioWords[word];
          return updatedAudioWords;
        });
        alert('录音已删除');
      } catch (error) {
        console.error('删除录音失败', error);
      }
    }
  };

  const updateWordStatus = async (word, isMastered) => {
    try {
      await axios.post(`${BACKEND_URL}/update_word_status/${userId}/${word}`, {
        is_mastered: isMastered  // 作为请求体发送
      });
  
      if (isMastered) {
        // 移动到熟词本
        const updatedUnmasteredWords = unmasteredWords.filter(w => w.word !== word);
        const updatedMasteredWords = [...masteredWords, words.find(w => w.word === word)];
        setUnmasteredWords(updatedUnmasteredWords);
        setMasteredWords(updatedMasteredWords);
      } else {
        // 移动到生词本
        const updatedMasteredWords = masteredWords.filter(w => w.word !== word);
        const updatedUnmasteredWords = [...unmasteredWords, words.find(w => w.word === word)];
        setMasteredWords(updatedMasteredWords);
        setUnmasteredWords(updatedUnmasteredWords);
      }
    } catch (error) {
      console.error('Error updating word status:', error);
    }
  };
  
  // 切换按钮点击处理函数
  const handleToggle = () => {
    setShowMastered(!showMastered);
  };

  return (
    <div>
      <h1>{showMastered ? '熟词本' : '生词本'}</h1>
      {/* 切换按钮 */}
      <button onClick={handleToggle}>
        {showMastered ? '切换到生词本' : '切换到熟词本'}
      </button>

      <ul>
        {(showMastered ? masteredWords : unmasteredWords).map((word, index) => (
          <li key={index}>
            <h3>{word.word}</h3>
            <p>音标: {word.pronunciation}</p>
            <p>解释: {word.meanings}</p>
            {/* 显示“已背次数” */}
            <p>已背次数: {word.review_count}</p>
            {/* 多个音频文件 */}
            <div>
              {console.log('word.audio:', word.audio)}
              {word.audio && typeof word.audio === 'object' && (
                Object.entries(word.audio).map(([filename, label], i) => (
                  <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <span style={{ marginRight: '10px', fontWeight: 'bold' }}>{label || '未定义'}:</span>
                    <audio controls src={`${AUDIO_URL}/audio/${filename}?t=${new Date().getTime()}`} />
                  </div>
                ))
              )}
            </div>
            {/* 录音功能 */}
            <div>
              <button
                id="recordButton"
                onMouseDown={() => {
                  document.getElementById("recordButton").innerText = "录音中...";
                  startRecording();
                }}
                onMouseUp={() => {
                  document.getElementById("recordButton").innerText = "按住录音";
                  stopRecording();
                }}
              >
                按住录音
              </button>

              {/* 显示录音后的播放和提交按钮 */}
              {audioUrl && (
                <div>
                  <audio controls src={audioUrl}></audio>
                  <button onClick={() => handleUploadAudio(word.word)}>提交</button>
                </div>
              )}

              {/* 显示已提交的音频和删除按钮 */}
              {audioWords[word.word] && (
                <div>
                  <span>我的录音：</span><audio controls src={`${AUDIO_URL}/audio/${audioWords[word.word]}?t=${new Date().getTime()}`}></audio>
                  <button onClick={() => handleDeleteAudio(word.word)}>删除</button>
                </div>
              )}
            </div>
            <>
            <QuestionButton
                  timestampId={`${userId}_${word.word}`}
                  questions={questions}
                  isQuestionExpanded={isQuestionExpanded}
                  isAskingQuestion={isAskingQuestion}
                  handleQuestionToggle={handleQuestionToggle}
              />
              {isAskingQuestion[`${userId}_${word.word}`] && (
              <QuestionForm
                  questionText={questionText}
                  setQuestionText={setQuestionText}
                  questionAudio={questionAudio}
                  setQuestionAudio={setQuestionAudio}
                  handleSubmitQuestion={handleSubmitQuestion}
                  timestampId={`${userId}_${word.word}`}
                  startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
                  stopQuestionRecording={stopQuestionRecording}
                  remainingPoints={remainingPoints}
                  estimatedPoints={estimatedPoints}
                  handleTextChange={handleTextChange}
                  renderAudio={renderAudio}
                  pendingAudio={pendingAudio}
                  setAssignTeacher={setAssignTeacher}
                  assignTeacher={assignTeacher}
                  needTeacher={needTeacher}
              />
              )}
              {isQuestionExpanded[`${userId}_${word.word}`] && (
              <AskQuestions
                  questionText={questionText}
                  setQuestionText={setQuestionText}
                  questionAudio={questionAudio}
                  setQuestionAudio={setQuestionAudio}
                  handleSubmitQuestion={handleSubmitQuestion}
                  timestampId={`${userId}_${word.word}`}
                  startQuestionRecording={startQuestionRecording}               // 将 startRecording 传递给 QuestionForm
                  stopQuestionRecording={stopQuestionRecording}
                  remainingPoints={remainingPoints}
                  estimatedPoints={estimatedPoints}
                  handleTextChange={handleTextChange}
                  renderAudio={renderAudio}
                  pendingAudio={pendingAudio}
                  qTimestampId={'qtimestamp_id'}
                  followupText={followupText}
                  handleSubmitFollowup={handleSubmitFollowup}
                  setFollowupText={setFollowupText}
                  questions={questions}
                  needTeacher={needTeacher}
              />
              )}    
            </>
            {/* 根据页面切换状态显示不同按钮 */}
            {!showMastered ? (
              <button onClick={() => updateWordStatus(word.word, true)}>已熟记</button>
            ) : (
              <button onClick={() => updateWordStatus(word.word, false)}>放回生词本</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WordOverview;
