import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminPage.css';

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState({});
  const [onlineStatus, setOnlineStatus] = useState({});
  const [redeemPoints, setRedeemPoints] = useState(0); // 生成的兑换码的积分
  const [redeemCount, setRedeemCount] = useState(0); // 生成的兑换码数量
  const [generatedCodes, setGeneratedCodes] = useState([]); // 生成的兑换码列表
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
  useEffect(() => {
    axios.get(`${BACKEND_URL}/users/`)
      .then(response => {
        const sortedUsers = response.data.sort((a, b) => a.id - b.id);
        setUsers(sortedUsers);

        const roles = {};
        sortedUsers.forEach(user => {
          roles[user.id] = user.role;
        });
        setSelectedRoles(roles);

        fetchOnlineStatus();
      })
      .catch(error => {
        console.error("There was an error fetching the users!", error);
      });
  }, []);

  const fetchOnlineStatus = () => {
    axios.get(`${BACKEND_URL}/users/online-status`)
      .then(response => {
        const status = {};
        response.data.forEach(user => {
          status[user.user_id] = user.is_online;
        });
        setOnlineStatus(status);
      })
      .catch(error => {
        console.error("Error fetching online status:", error);
      });
  };

  const handleRoleChange = (userId, newRole) => {
    setSelectedRoles({
      ...selectedRoles,
      [userId]: newRole,
    });
  };

  const handleConfirmChange = (userId) => {
    const newRole = selectedRoles[userId];
    axios.post(`${BACKEND_URL}/update-role/`, { user_id: userId, role: newRole })
      .then(response => {
        setUsers(users.map(user => 
          user.id === userId ? { ...user, role: newRole } : user
        ));
        alert("角色已成功更新");
      })
      .catch(error => {
        console.error("There was an error updating the user role!", error);
        alert("角色更新失败");
      });
  };

  // 处理生成兑换码的积分和数量输入
  const handlePointsChange = (e) => setRedeemPoints(e.target.value);
  const handleCountChange = (e) => setRedeemCount(e.target.value);

  // 生成兑换码
  const handleGenerateCodes = () => {
    axios.post(`${BACKEND_URL}/generate-redeem-codes`, {
      points: parseInt(redeemPoints),
      count: parseInt(redeemCount)
    })
      .then(response => {
        setGeneratedCodes(response.data.redeem_codes); // 显示生成的兑换码
        alert("兑换码生成成功！");
      })
      .catch(error => {
        console.error("Error generating redeem codes:", error);
        alert("兑换码生成失败！");
      });
  };
  

  return (
    <div className="admin-page">
      <h1>用户管理</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Role</th>
            <th>在线状态</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{onlineStatus[user.id] ? '在线' : '离线'}</td>
              <td>
                {user.role !== 'superadmin' && (
                  <div className="action-group">
                    <select 
                      className="role-select"
                      value={selectedRoles[user.id]} 
                      onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    >
                      <option value="student">学生</option>
                      <option value="teacher">教师</option>
                      <option value="promoter">推广员</option>
                    </select>
                    <button 
                      className="confirm-button" 
                      onClick={() => handleConfirmChange(user.id)}
                    >
                      确认更改
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>生成兑换码</h2>
      <div>
        <label>积分数：</label>
        <input type="number" value={redeemPoints} onChange={handlePointsChange} />
      </div>
      <div>
        <label>兑换码数量：</label>
        <input type="number" value={redeemCount} onChange={handleCountChange} />
      </div>
      <button onClick={handleGenerateCodes}>生成兑换码</button>

      {generatedCodes.length > 0 && (
        <div>
          <h3>生成的兑换码</h3>
          <ul>
            {generatedCodes.map((codeInfo, index) => (
              <li key={index}>
                兑换码: {codeInfo.code}, 积分: {codeInfo.points}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AdminPage;
