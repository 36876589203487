// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .admin-page h1 {
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  table th, table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .action-group {
    display: flex;
    align-items: center;
  }
  
  .role-select {
    padding: 5px 10px;
    font-size: 14px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .confirm-button {
    padding: 5px 15px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background-color: #0056b3;
  }
  
  .role-select, .confirm-button {
    height: 35px;
  }
  `, "",{"version":3,"sources":["webpack://./src/AdminPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;EACd","sourcesContent":[".admin-page {\r\n    padding: 20px;\r\n    font-family: Arial, sans-serif;\r\n  }\r\n  \r\n  .admin-page h1 {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  table th, table td {\r\n    padding: 10px;\r\n    text-align: left;\r\n    border-bottom: 1px solid #ddd;\r\n  }\r\n  \r\n  .action-group {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .role-select {\r\n    padding: 5px 10px;\r\n    font-size: 14px;\r\n    margin-right: 10px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .confirm-button {\r\n    padding: 5px 15px;\r\n    font-size: 14px;\r\n    color: #fff;\r\n    background-color: #007bff;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .confirm-button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  \r\n  .role-select, .confirm-button {\r\n    height: 35px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
