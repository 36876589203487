import axios from 'axios';
import { isTokenExpired, refreshAccessToken } from './authService';

let isRefreshing = false;
let refreshTokenPromise = null;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api";
axios.defaults.baseURL = BACKEND_URL;

// 封装刷新请求
async function getNewToken() {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshTokenPromise = refreshAccessToken()
      .then((newToken) => {
        isRefreshing = false;
        return newToken;
      })
      .catch((error) => {
        console.error("Token refresh failed in getNewToken:", error);
        isRefreshing = false;
        refreshTokenPromise = null;
        throw error;
      });
  } else {
    console.log("Waiting for token refresh to complete");
  }
  return refreshTokenPromise;
}


// 设置 Axios 请求拦截器
axios.interceptors.request.use(
  async (config) => {
    // 跳过登录请求和刷新 token 请求
    if (config.url.endsWith('/login/') || config.url.endsWith('/token/refresh/')) {
      return config; // 直接返回 config，不进行 token 检查
    }

    // 获取当前 token
    let token = localStorage.getItem('token');

    // 仅在有 token 时检查是否过期
    if (token) {
      if (isTokenExpired(token)) {
        try {
          token = await getNewToken();  // 使用新的封装函数来获取新的 token
        } catch (error) {
          console.error("Token refresh failed", error);
          return Promise.reject(error);  // 如果刷新失败，抛出错误
        }
      }
      // 更新请求头中的 token
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const setupAxiosInterceptors = (setIsLoggedIn, setIsModalOpen) => {
  axios.interceptors.response.use(
    response => response,  // 正常请求的响应直接返回
    async error => {
      if (error.response && error.response.status === 403 && error.response.data.detail === "Refresh token expired, please log in again") {
        console.log("Refresh token expired. Logging out...");
        
        // 触发自动退出登录状态并显示登录框
        setIsLoggedIn(false);
        setIsModalOpen(true);
        
        // 清除本地存储的 token
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");        // 清除 access token
        localStorage.removeItem("refresh_token"); // 清除 refresh token
      }
      return Promise.reject(error);
    }
  );
};